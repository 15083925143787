.see-product {
 display: flex !important;
 justify-content: center !important;
 white-space: nowrap;

 @media (max-width: 768px) {
  font-size: 0.8rem !important;
  padding: 0.5rem 1rem !important;
 }
}
