@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.landing {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif !important;
}

.title {
  font-family: "Poppins", sans-serif !important;
}

.nav-responsive {
  height: 60px;
  font-family: "Inter", sans-serif !important;
}

.text-responsive-6_5xl {
  font-size: 2.5rem;
}

@media (min-width: 768px) {
  .nav-responsive {
    height: 80px;
  }

  .text-responsive-6_5xl {
    font-size: 3.5rem;
  }
}

.font-system {
  font-family: "Inter", sans-serif !important;
}

.content-grid {
  --padding-inline: 1rem;
  --content-max-width: 1200px;
  --breakout-max-width: 1280px;

  --breakout-size: calc((var(--breakout-max-width) - var(--content-max-width)) / 2);

  display: grid;
  grid-template-columns:
    [full-width-start] minmax(var(--padding-inline), 1fr)
    [breakout-start] minmax(0, var(--breakout-size))
    [content-start] min(100% - (var(--padding-inline) * 2), var(--content-max-width))
    [content-end]
    minmax(0, var(--breakout-size)) [breakout-end]
    minmax(var(--padding-inline), 1fr) [full-width-end];
}

.content-grid > :not(.breakout, .full-width),
.full-width > :not(.breakout, .full-width) {
  grid-column: content;
}

.content-grid > .breakout {
  grid-column: breakout;
}

.content-grid > .full-width {
  grid-column: full-width;

  display: grid;
  grid-template-columns: inherit;
}
