.background-header {
  background-position-x: 30% !important;
  background-image: url("../../../images/background.png");
  background-size: cover;
  overflow: hidden;
}

.card-blur-1 {
  position: absolute;
  bottom: 180px;
  right: -50%;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.2);
}

.card-blur-2 {
  position: absolute;
  bottom: 100px;
  left: -40%;
  width: 65%;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.5);
}

.phone-lines {
  position: relative;
  margin-right: 110px;
}

@media (max-width: 1200px) {
  .phone-lines {
    margin-right: 0px;
  }
}
