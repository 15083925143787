.forgot-password-a {
  color: black;
  padding: .3em;
  border-radius: .3em;
}

.forgot-password-a:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}