.product-grid {
  display: grid;
  grid-template-columns: 550px 1fr;
  align-items: flex-start;
  column-gap: 32px;
}

.scroll-down {
  transform: translateY(50%);
}

@media (max-width: 1500px) {
  .product-grid {
    grid-template-columns: 300px 1fr;
    column-gap: 16px;
  }
}

@media (max-width: 1199px) {
  .button-div-buy {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100px;
  }
}


@media (max-width: 768px) {
  .product-grid {
    grid-template-columns: 1fr;
  }
}
