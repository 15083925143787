.search-bar-green {
  cursor: pointer;
  transition: .2s;
}

.search-bar-green:hover {
  background: #46a748 !important;
  color: white;
}

.search-bar-green:active {
  background: #59d55b !important;
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #76e278;
}