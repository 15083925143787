.background-exclusive-section {
  /* background-image: url("../../../images/exclusive-background.png"); */
  background: linear-gradient(315deg, #433ac880 0%, rgba(0, 0, 0, 0) 50%, #433ac880 100%),
    url("../../../images/exclusive-background.png");
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 768px) {
  .background-exclusive-section {
    min-height: 375px;
    width: 100vw;
  }
}
