.product-selected-image {
  object-fit: cover;
}

.p-rating-item {
  width: 24px;
  height: 24px;
}

.ql-editor * {
  font-family: 'Poppins', sans-serif;
}

.gallery {
  width: 100%;
  height: 100%;
}

.gallery-img {
  flex: 1 1;
  aspect-ratio: 1 / 1;
}

.detailed-product-title {
  max-width: 100%;
}

.relationed-products {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 10px;
  justify-items: flex-start;
}

.selectedInfo {
  position: relative;
}

.selectedInfo::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
}

.productCard-vizualization {
  top: 10px;
  left: 10px;
}

.dialog-compare-prices {
  width: 80%;
  height: 100vh;
}

@media (max-width: 1750px) {
  .dialog-compare-prices {
    width: 100vw;
  }
}

@media (max-width: 991px) {
  .selectedInfo::after {
    bottom: 5px;
  }
}

@media (min-width: 1510px) {
  .product-title-container {
    max-width: 600px;
  }

  .gallery {
    width: 300;
    height: 300px;
  }
}

@media (max-width: 1510px) {
  .relationed-products {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 1300px) {
  .relationed-products {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 768px) {
  .gallery {
    margin: 10px auto;
    height: auto;
  }

  .relationed-products {
    grid-template-columns: repeat(3, 1fr);
  }

  .p-menuitem-text {
    font-size: .7rem !important;
  }

  .gallery-img {
    width: 300px;
    height: 300px;
  }

  .gallery-thumbnail {
    display: none;
  }

  .p-galleria-thumbnail-prev,
  .p-galleria-thumbnail-next {
    display: none !important;
  }

  .gallery {
    width: 300px;
    margin-bottom: 20px;
  }

  .product-state-tag {
    margin-top: -.1rem;
  }
}

@media (max-width: 550px) {
  .relationed-products {
    grid-template-columns: repeat(2, 1fr);
    justify-items: normal;
  }
}
