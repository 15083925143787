.modal-review-rating {
  width: 50vw;
  max-height: 60vh;
}

@media (max-width: 1200px) {
  .modal-review-rating {
    width: 90vw;
  }
}
