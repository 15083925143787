.swiper-button-next:after,
.swiper-button-prev:after {
  color: white;
  font-size: 20px;
}

.swiper-button-next,
.swiper-button-prev {
  width: 40px;
  height: 40px;
  background-color: var(--primary-color);
  opacity: .9;
  border-radius: 100%;
  padding: 10px;
}

.custom-swiper {
  /* Defina uma largura para o carrossel */
  width: 100%;
}

.custom-slide {
  /* Defina uma largura para os elementos do carrossel */
  min-width: 150px;
}

@media (max-width: 768px) {

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 10px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 30px;
    height: 30px;
  }
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  pointer-events: visible;
}
