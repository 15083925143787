h1, h2, h3, h4, h5, h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

@keyframes moveDown {
  0% {
      transform: translateY(-20px);
  }
  100% {
      transform: translateY(0px);
  }
}

@keyframes moveUp {
  0% {
      transform: translateY(20px);
  }
  100% {
      transform: translateY(0px);
  }
}

.animated-text-down {
  animation: moveDown .4s;
}

.animated-text-up {
  animation: moveUp .4s;
}

.button-blank {
  all: unset;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: .3em;
  transition: .3s;
}

.color-light-gray {
  color: #dfdfe7;
}

.color-dark-gray {
  color: #616161;
}

.button-blank-nohover {
  all: unset;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: .3em;
}

.a-no-hover:hover {
  background-color: initial !important;
  color: #252529 !important;
}

.button-blank:hover {
  color: #46a748;
  transition: .2s;
  border-radius: .3em;
}

.button-blank:focus {
  outline: orange 5px auto;
}

.button-blank-nohover:focus {
  outline: orange 5px auto;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
  margin-top: 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

mark {
  background: #FFF8E1;
  padding: 0.25rem 0.4rem;
  border-radius: 3px;
  font-family: monospace;
}

blockquote {
  margin: 1rem 0;
  padding: 0 2rem;
  border-left: 4px solid #90A4AE;
}

hr {
  border-top: solid #E0E0E0;
  border-width: 1px 0 0 0;
  margin: 1rem 0;
}

p {
  margin: 0 0 1rem 0;
  line-height: 1.5;
}
p:last-child {
  margin-bottom: 0;
}

.loader-screen {
  width: 100%;
  min-height: 100%;
  background-color: #212121;
  position: absolute;
}
.loader-screen .loader {
  margin: 0 auto;
  width: 60px;
  height: 50px;
  text-align: center;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 500;
}
.loader-screen .loader > div {
  height: 100%;
  width: 8px;
  display: inline-block;
  float: left;
  margin-left: 2px;
  animation: delay 0.8s infinite ease-in-out;
}
.loader-screen .loader .bar1 {
  background-color: #754fa0;
}
.loader-screen .loader .bar2 {
  background-color: #09b7bf;
  animation-delay: -0.7s;
}
.loader-screen .loader .bar3 {
  background-color: #90d36b;
  animation-delay: -0.6s;
}
.loader-screen .loader .bar4 {
  background-color: #f2d40d;
  animation-delay: -0.5s;
}
.loader-screen .loader .bar5 {
  background-color: #fcb12b;
  animation-delay: -0.4s;
}
.loader-screen .loader .bar6 {
  background-color: #ed1b72;
  animation-delay: -0.3s;
}

@keyframes delay {
  0%, 40%, 100% {
    transform: scaleY(0.05);
    -webkit-transform: scaleY(0.05);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #212121;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  min-height: 100%;
  background-color: #f5f5f5;
}

a {
  text-decoration: none;
  color: #0288D1;
  transition: color 0.3s;
}

.layout-wrapper .route-bar {
  min-height: 32px;
  background-color: #ffffff;
  border-bottom: 1px solid #e8e8e8;
}
.layout-wrapper .route-bar .route-bar-breadcrumb {
  padding: 6px 25px;
  display: inline-block;
}
.layout-wrapper .route-bar .route-bar-breadcrumb ul {
  margin: 0;
  padding: 0;
  list-style: none;
  color: #727272;
  display: inline-block;
}
.layout-wrapper .route-bar .route-bar-breadcrumb ul li {
  margin-right: 0.25em;
  list-style-type: none;
  display: inline-block;
  color: #727272;
  text-transform: capitalize;
}
.layout-wrapper .route-bar .route-bar-breadcrumb ul li button {
  color: #727272;
}

.layout-mask {
  display: none;
  background-color: #252529;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.layout-wrapper .layout-topbar {
  position: fixed;
  height: 60px;
  top: 0;
  left: 250px;
  right: 0;
  z-index: 997;
  padding: 8px 25px;
  background-color: #272360;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.layout-wrapper .layout-topbar:after {
  content: "";
  display: table;
  clear: both;
}
.layout-wrapper .layout-topbar .layout-topbar-logo {
  display: none;
}
.layout-wrapper .layout-topbar .layout-topbar-logo img {
  margin-top: 7px;
  height: 30px;
}
.layout-wrapper .layout-topbar .layout-menu-button {
  cursor: pointer;
  float: left;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
  border-radius: 50%;
  transition: background-color 0.3s;
}
.layout-wrapper .layout-topbar .layout-menu-button i {
  line-height: inherit;
  font-size: 24px;
}
.layout-wrapper .layout-topbar .layout-menu-button:hover {
  background-color: #5F55ED;
}
.layout-wrapper .layout-topbar #topbar-menu-button {
  cursor: pointer;
  font-size: 28px;
  width: 44px;
  height: 44px;
  line-height: 44px;
  display: none;
  float: right;
  text-align: center;
  border-radius: 50%;
  color: #ffffff;
  transition: background-color 0.3s;
}
.layout-wrapper .layout-topbar #topbar-menu-button i {
  line-height: inherit;
  font-size: 24px;
}
.layout-wrapper .layout-topbar #topbar-menu-button:hover {
  background-color: #5F55ED;
}
.layout-wrapper .layout-topbar .topbar-menu {
  float: right;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.layout-wrapper .layout-topbar .topbar-menu > li {
  float: right;
  position: relative;
  margin-left: 14px;
}
.layout-wrapper .layout-topbar .topbar-menu > li > button {
  cursor: pointer;
  position: relative;
  display: block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  border-radius: 50%;
  transition: background-color 0.3s;
}
.layout-wrapper .layout-topbar .topbar-menu > li > button .topbar-icon {
  color: #ffffff;
  line-height: inherit;
  font-size: 24px;
}
.layout-wrapper .layout-topbar .topbar-menu > li > button > img {
  width: 36px;
  margin-top: 4px;
}
.layout-wrapper .layout-topbar .topbar-menu > li > button:hover {
  background-color: #5F55ED;
}
.layout-wrapper .layout-topbar .topbar-menu > li > button .topbar-item-name {
  display: none;
}
.layout-wrapper .layout-topbar .topbar-menu > li > button .topbar-badge {
  position: absolute;
  right: 4px;
  top: 4px;
  background-color: #e0284f;
  color: #ffffff;
  text-align: center;
  line-height: 16px;
  min-width: 16px;
  min-height: 16px;
  display: block;
  font-size: 12px;
  border-radius: 100%;
}
.layout-wrapper .layout-topbar .topbar-menu > li > ul {
  position: absolute;
  border: 1px solid #e0e0e0;
  top: 55px;
  right: -18px;
  margin: 0;
  display: none;
  min-width: 250px;
  padding: 8px 12px;
  list-style-type: none;
  background-color: #ffffff;
  animation-duration: 0.3s;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);
}
.layout-wrapper .layout-topbar .topbar-menu > li > ul.layout-submenu-container-enter {
  max-height: 0;
  display: none;
}
.layout-wrapper .layout-topbar .topbar-menu > li > ul.layout-submenu-container-enter-active {
  max-height: 500px;
}
.layout-wrapper .layout-topbar .topbar-menu > li > ul.layout-submenu-container-exit {
  max-height: 500px;
}
.layout-wrapper .layout-topbar .topbar-menu > li > ul.layout-submenu-container-exit-active {
  max-height: 0;
  display: none;
}
.layout-wrapper .layout-topbar .topbar-menu > li > ul:before, .layout-wrapper .layout-topbar .topbar-menu > li > ul:after {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  left: 210px;
}
.layout-wrapper .layout-topbar .topbar-menu > li > ul:before {
  border-color: rgba(216, 216, 220, 0);
  border-bottom-color: #e0e0e0;
  border-width: 10px;
  margin-left: -10px;
}
.layout-wrapper .layout-topbar .topbar-menu > li > ul:after {
  border-color: rgba(244, 244, 249, 0);
  border-bottom-color: #ffffff;
  border-width: 9px;
  margin-left: -9px;
}
.layout-wrapper .layout-topbar .topbar-menu > li > ul button {
  box-sizing: border-box;
  padding: 8px 12px;
  text-align: left;
  display: block;
  width: 100%;
  color: #727272;
  position: relative;
  border-radius: 3px;
  transition: background-color 0.3s;
}
.layout-wrapper .layout-topbar .topbar-menu > li > ul button i {
  margin-right: 8px;
  color: #727272;
}
.layout-wrapper .layout-topbar .topbar-menu > li > ul button img {
  margin-right: 8px;
  width: 24px;
}
.layout-wrapper .layout-topbar .topbar-menu > li > ul button i, .layout-wrapper .layout-topbar .topbar-menu > li > ul button img, .layout-wrapper .layout-topbar .topbar-menu > li > ul button span {
  vertical-align: middle;
}
.layout-wrapper .layout-topbar .topbar-menu > li > ul button .topbar-submenuitem-badge {
  background-color: #e0284f;
  color: #ffffff;
  text-align: center;
  line-height: 16px;
  min-width: 16px;
  height: 16px;
  font-size: 12px;
  border-radius: 100%;
  display: inline-block;
  margin-left: 8px;
}
.layout-wrapper .layout-topbar .topbar-menu > li > ul button:hover {
  background-color: #e0e0e0;
}
.layout-wrapper .layout-topbar .topbar-menu > li.active-topmenuitem > ul {
  display: block;
}

@media screen and (max-width: 896px) {
  .layout-wrapper .layout-topbar {
    text-align: center;
  }
  .layout-wrapper .layout-topbar .layout-topbar-logo {
    display: inline-block;
  }
  .layout-wrapper .layout-topbar #topbar-menu-button {
    display: block;
  }
  .layout-wrapper .layout-topbar .topbar-menu {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    position: absolute;
    top: 70px;
    right: 25px;
    width: 250px;
    animation-duration: 0.3s;
    display: none;
    list-style-type: none;
    margin: 0;
    padding: 8px 12px;
    box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);
  }
  .layout-wrapper .layout-topbar .topbar-menu:before, .layout-wrapper .layout-topbar .topbar-menu:after {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    left: 225px;
  }
  .layout-wrapper .layout-topbar .topbar-menu:before {
    border-color: rgba(216, 216, 220, 0);
    border-bottom-color: #e0e0e0;
    border-width: 10px;
    margin-left: -10px;
  }
  .layout-wrapper .layout-topbar .topbar-menu:after {
    border-color: rgba(244, 244, 249, 0);
    border-bottom-color: #ffffff;
    border-width: 9px;
    margin-left: -9px;
  }
  .layout-wrapper .layout-topbar .topbar-menu.topbar-menu-visible {
    display: block;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li {
    width: 100%;
    margin: 0;
    float: none;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li.active-topmenuitem > ul {
    display: block;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li.user-profile > button img {
    margin-right: 8px;
    margin-top: 0;
    width: 24px;
    vertical-align: middle;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li button {
    box-sizing: border-box;
    height: auto;
    line-height: 1;
    width: 100%;
    display: block;
    padding: 8px 12px;
    position: relative;
    text-align: left;
    border-radius: 3px;
    transition: background-color 0.3s;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li button .topbar-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    font-size: 16px;
    color: #727272;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li button:hover {
    background-color: #e0e0e0;
    color: #212121;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li button .topbar-item-name {
    display: inline-block;
    vertical-align: middle;
    color: #727272;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li button .topbar-badge {
    background-color: #e0284f;
    color: #ffffff;
    text-align: center;
    line-height: 16px;
    min-width: 16px;
    height: 16px;
    font-size: 12px;
    border-radius: 100%;
    display: inline-block;
    position: static;
    margin-left: 8px;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li > ul {
    display: none;
    list-style-type: none;
    padding: 0 0 0 0;
    margin: 0;
    position: static;
    top: auto;
    left: auto;
    width: 100%;
    min-width: auto;
    border: 0 none;
    box-shadow: none;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li > ul:before, .layout-wrapper .layout-topbar .topbar-menu > li > ul:after {
    display: none;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li > ul li button {
    padding-left: 28px;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li > ul li button img {
    margin-right: 8px;
  }
}
.layout-wrapper .layout-menu-container {
  position: fixed;
  height: 100%;
  z-index: 997;
}
.layout-wrapper .layout-menu-container .layout-menu-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.layout-wrapper .layout-menu-container .layout-menu-logo {
  background-color: #46a748;
}
.layout-wrapper .layout-menu-container .layout-menu-logo button {
  display: block;
  text-align: left;
  height: 60px;
  padding-left: 12px;
  width: 100%;
}
.layout-wrapper .layout-menu-container .layout-menu-logo button img {
  vertical-align: middle;
  height: 30px;
}
.layout-wrapper .layout-menu-container .layout-profile.layout-profile-active .layout-profile-icon {
  transform: rotate(-180deg);
}
.layout-wrapper .layout-menu-container .layout-profile .layout-profile-button {
  padding: 20px 12px;
  display: block;
  position: relative;
  width: 100%;
  transition: background-color 0.3s;
}
.layout-wrapper .layout-menu-container .layout-profile .layout-profile-button:after {
  content: "";
  display: table;
  clear: both;
}
.layout-wrapper .layout-menu-container .layout-profile .layout-profile-button img {
  width: 36px;
  display: inline-block;
}
.layout-wrapper .layout-menu-container .layout-profile .layout-profile-button .layout-profile-userinfo {
  display: inline-block;
  vertical-align: top;
  margin-left: 8px;
}
.layout-wrapper .layout-menu-container .layout-profile .layout-profile-button .layout-profile-userinfo .layout-profile-name {
  display: inline-block;
  font-weight: 700;
}
.layout-wrapper .layout-menu-container .layout-profile .layout-profile-button .layout-profile-userinfo .layout-profile-role {
  display: block;
}
.layout-wrapper .layout-menu-container .layout-profile .layout-profile-button .layout-profile-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -8px;
  font-size: 16px;
  transition: transform 0.3s;
}
.layout-wrapper .layout-menu-container .layout-profile .layout-profile-menu {
  list-style: none;
  padding: 0 12px 0;
  margin: 0;
  overflow: hidden;
}
.layout-wrapper .layout-menu-container .layout-profile .layout-profile-menu.layout-profile-menu-enter {
  max-height: 0;
}
.layout-wrapper .layout-menu-container .layout-profile .layout-profile-menu.layout-profile-menu-enter-active {
  overflow: hidden;
  max-height: 500px;
  transition: max-height 1s ease-in-out;
}
.layout-wrapper .layout-menu-container .layout-profile .layout-profile-menu.layout-profile-menu-enter-done {
  transform: none;
}
.layout-wrapper .layout-menu-container .layout-profile .layout-profile-menu.layout-profile-menu-exit {
  max-height: 500px;
}
.layout-wrapper .layout-menu-container .layout-profile .layout-profile-menu.layout-profile-menu-exit-active {
  overflow: hidden;
  max-height: 0;
  transition: max-height 400ms cubic-bezier(0.86, 0, 0.07, 1);
}
.layout-wrapper .layout-menu-container .layout-profile .layout-profile-menu li button {
  display: block;
  padding: 8px 4px;
  text-align: left;
  width: 100%;
  border-radius: 3px;
  transition: background-color 0.3s;
}
.layout-wrapper .layout-menu-container .layout-profile .layout-profile-menu li button i {
  margin-right: 8px;
  vertical-align: middle;
}
.layout-wrapper .layout-menu-container .layout-profile .layout-profile-menu li button span {
  font-weight: 700;
  display: inline-block;
  vertical-align: middle;
}
.layout-wrapper .layout-menu-container .layout-profile .layout-profile-menu li:last-child {
  padding-bottom: 14px;
}
.layout-wrapper .layout-menu-container .layout-menu {
  list-style-type: none;
  padding: 14px 12px 120px 12px;
  margin: 0;
  height: 100%;
}
.layout-wrapper .layout-menu-container .layout-menu .layout-root-menuitem ul > li ul {
  overflow: hidden;
}
.layout-wrapper .layout-menu-container .layout-menu .layout-root-menuitem ul > li ul.layout-submenu-container-enter {
  max-height: 0;
}
.layout-wrapper .layout-menu-container .layout-menu .layout-root-menuitem ul > li ul.layout-submenu-container-enter-active {
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}
.layout-wrapper .layout-menu-container .layout-menu .layout-root-menuitem ul > li ul.layout-submenu-container-enter-done {
  transform: none;
}
.layout-wrapper .layout-menu-container .layout-menu .layout-root-menuitem ul > li ul.layout-submenu-container-exit {
  max-height: 1000px;
}
.layout-wrapper .layout-menu-container .layout-menu .layout-root-menuitem ul > li ul.layout-submenu-container-exit-active {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.45s cubic-bezier(0, 1, 0, 1);
}
.layout-wrapper .layout-menu-container .layout-menu li a,
.layout-wrapper .layout-menu-container .layout-menu li .p-link {
  display: block;
  width: 100%;
  padding: 8px 0;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  transition: background-color 0.3s;
}
.layout-wrapper .layout-menu-container .layout-menu li a .layout-menuitem-icon,
.layout-wrapper .layout-menu-container .layout-menu li .p-link .layout-menuitem-icon {
  margin-right: 6px;
  vertical-align: middle;
}
.layout-wrapper .layout-menu-container .layout-menu li a .layout-menuitem-text,
.layout-wrapper .layout-menu-container .layout-menu li .p-link .layout-menuitem-text {
  font-weight: 700;
  vertical-align: middle;
}
.layout-wrapper .layout-menu-container .layout-menu li a .layout-submenu-toggler,
.layout-wrapper .layout-menu-container .layout-menu li .p-link .layout-submenu-toggler {
  position: absolute;
  right: 0px;
  top: 50%;
  font-size: 16px;
  margin-top: -8px;
  transition: transform 0.3s;
}
.layout-wrapper .layout-menu-container .layout-menu li a .menuitem-badge,
.layout-wrapper .layout-menu-container .layout-menu li .p-link .menuitem-badge {
  position: absolute;
  display: block;
  right: 10px;
  height: 20px;
  width: 44px;
  line-height: 20px;
  top: 50%;
  font-size: 12px;
  margin-top: -10px;
  font-weight: 700;
  background-color: #2196F3;
  color: #ffffff;
  text-align: center;
  border-radius: 10px;
}
.layout-wrapper .layout-menu-container .layout-menu li a.rotated-icon .layout-menuitem-icon,
.layout-wrapper .layout-menu-container .layout-menu li .p-link.rotated-icon .layout-menuitem-icon {
  transform: rotate(90deg);
}
.layout-wrapper .layout-menu-container .layout-menu li.active-menuitem > a .layout-submenu-toggler,
.layout-wrapper .layout-menu-container .layout-menu li.active-menuitem > .p-link .layout-submenu-toggler {
  transform: rotate(-180deg);
}
.layout-wrapper .layout-menu-container .layout-menu li ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.layout-wrapper .layout-menu-container .layout-menu li ul li a,
.layout-wrapper .layout-menu-container .layout-menu li ul li .p-link {
  padding-left: 4px;
}
.layout-wrapper .layout-menu-container .layout-menu li ul li li a,
.layout-wrapper .layout-menu-container .layout-menu li ul li li .p-link {
  padding-left: 16px;
}
.layout-wrapper .layout-menu-container .layout-menu li ul li li li a,
.layout-wrapper .layout-menu-container .layout-menu li ul li li li .p-link {
  padding-left: 32px;
}
.layout-wrapper .layout-menu-container .layout-menu li ul li li li li a,
.layout-wrapper .layout-menu-container .layout-menu li ul li li li li .p-link {
  padding-left: 48px;
}
.layout-wrapper .layout-menu-container .layout-menu li ul li li li li li a,
.layout-wrapper .layout-menu-container .layout-menu li ul li li li li li .p-link {
  padding-left: 64px;
}
.layout-wrapper .layout-menu-container .layout-menu li ul li li li li li li a,
.layout-wrapper .layout-menu-container .layout-menu li ul li li li li li li .p-link {
  padding-left: 80px;
}
.layout-wrapper .layout-menu-container .layout-menu li ul li ul {
  overflow: hidden;
}
.layout-wrapper .layout-menu-container .layout-menu > li {
  margin-bottom: 16px;
}
.layout-wrapper .layout-menu-container .layout-menu > li > a,
.layout-wrapper .layout-menu-container .layout-menu > li > .p-link {
  display: none;
}
.layout-wrapper .layout-menu-container .layout-menu > li > div {
  margin-bottom: 4px;
}
.layout-wrapper .layout-menu-container .layout-menu .layout-menu-tooltip {
  display: none;
  padding: 0 5px;
  position: absolute;
  left: 61px;
  top: 17px;
  line-height: 1;
}
.layout-wrapper .layout-menu-container .layout-menu .layout-menu-tooltip .layout-menu-tooltip-text {
  padding: 6px 8px;
  font-weight: 700;
  background-color: #212121;
  color: #ffffff;
  min-width: 75px;
  white-space: nowrap;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);
}
.layout-wrapper .layout-menu-container .layout-menu .layout-menu-tooltip .layout-menu-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #212121;
}
.layout-wrapper .layout-menu-container .p-link {
  border-radius: 0;
}
.layout-wrapper.layout-menu-light .layout-menu-container {
  background-color: #ffffff;
}
.layout-wrapper.layout-menu-light .layout-menu-container .layout-menu-wrapper {
  border-right: 1px solid #E0E0E0;
}
.layout-wrapper.layout-menu-light .layout-menu-container .layout-profile {
  border-bottom: 1px solid #E0E0E0;
}
.layout-wrapper.layout-menu-light .layout-menu-container .layout-profile .layout-profile-button .layout-profile-name {
  color: #727272;
}
.layout-wrapper.layout-menu-light .layout-menu-container .layout-profile .layout-profile-button .layout-profile-role {
  color: #727272;
}
.layout-wrapper.layout-menu-light .layout-menu-container .layout-profile .layout-profile-button .layout-profile-icon {
  color: #727272;
}
.layout-wrapper.layout-menu-light .layout-menu-container .layout-profile .layout-profile-button:hover {
  background-color: #e0e0e0;
}
.layout-wrapper.layout-menu-light .layout-menu-container .layout-profile .layout-profile-menu button {
  color: #727272;
}
.layout-wrapper.layout-menu-light .layout-menu-container .layout-profile .layout-profile-menu button i {
  color: #727272;
}
.layout-wrapper.layout-menu-light .layout-menu-container .layout-profile .layout-profile-menu button:hover {
  background-color: #e0e0e0;
}
.layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li a .layout-menuitem-text,
.layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li .p-link .layout-menuitem-text {
  color: #727272;
}
.layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li a .layout-menuitem-icon,
.layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li a .layout-submenu-toggler,
.layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li .p-link .layout-menuitem-icon,
.layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li .p-link .layout-submenu-toggler {
  color: #727272;
}
.layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li a:hover,
.layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li .p-link:hover {
  background-color: #e0e0e0;
}
.layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li a.active-route .layout-menuitem-text, .layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li a.active-route .layout-menuitem-icon,
.layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li .p-link.active-route .layout-menuitem-text,
.layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li .p-link.active-route .layout-menuitem-icon {
  color: #2196F3;
}
.layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li a.router-link-active .layout-menuitem-text, .layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li a.router-link-active .layout-menuitem-icon, .layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li a.router-link-exact-active .layout-menuitem-text, .layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li a.router-link-exact-active .layout-menuitem-icon,
.layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li .p-link.router-link-active .layout-menuitem-text,
.layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li .p-link.router-link-active .layout-menuitem-icon,
.layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li .p-link.router-link-exact-active .layout-menuitem-text,
.layout-wrapper.layout-menu-light .layout-menu-container .layout-menu li .p-link.router-link-exact-active .layout-menuitem-icon {
  color: #2196F3;
}
.layout-wrapper.layout-menu-light .layout-menu-container .layout-menu > li > div {
  color: #727272;
}
.layout-wrapper.layout-menu-dark .layout-menu-container {
  background-color: #212121;
}
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu-wrapper {
  border-right: 0 none;
}
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-profile {
  border-bottom: 1px solid #373737;
}
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-profile button .layout-profile-name {
  color: #BDBDBD;
}
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-profile button .layout-profile-role {
  color: #979797;
}
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-profile button .layout-profile-icon {
  color: #BDBDBD;
}
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-profile button:hover {
  background-color: #373737;
}
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-profile .layout-profile-menu button {
  color: #BDBDBD;
}
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-profile .layout-profile-menu button i {
  color: #BDBDBD;
}
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-profile .layout-profile-menu button:hover {
  background-color: #373737;
}
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li a .layout-menuitem-text,
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li .p-link .layout-menuitem-text {
  color: #BDBDBD;
}
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li a .layout-menuitem-icon,
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li a .layout-submenu-toggler,
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li .p-link .layout-menuitem-icon,
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li .p-link .layout-submenu-toggler {
  color: #BDBDBD;
}
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li a:hover,
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li .p-link:hover {
  background-color: #373737;
}
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li a.active-route .layout-menuitem-text, .layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li a.active-route .layout-menuitem-icon,
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li .p-link.active-route .layout-menuitem-text,
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li .p-link.active-route .layout-menuitem-icon {
  color: #2196F3;
}
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li a.router-link-active .layout-menuitem-text, .layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li a.router-link-active .layout-menuitem-icon, .layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li a.router-link-exact-active .layout-menuitem-text, .layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li a.router-link-exact-active .layout-menuitem-icon,
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li .p-link.router-link-active .layout-menuitem-text,
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li .p-link.router-link-active .layout-menuitem-icon,
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li .p-link.router-link-exact-active .layout-menuitem-text,
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu li .p-link.router-link-exact-active .layout-menuitem-icon {
  color: #2196F3;
}
.layout-wrapper.layout-menu-dark .layout-menu-container .layout-menu > li > div {
  color: #757575;
}
.layout-wrapper .layout-main {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.layout-wrapper .layout-main .layout-content {
  padding: 16px;
  flex: 1 1 0;
}

@media screen and (min-width: 897px) {
  .layout-wrapper.layout-static .layout-menu-container {
    width: 250px;
    left: 0;
    transition: transform 0.3s;
  }
  .layout-wrapper.layout-static .layout-menu-container.layout-menu-container-inactive {
    transform: translateX(-100%);
  }
  .layout-wrapper.layout-static .layout-topbar {
    left: 250px;
    transition: left 0.3s;
  }
  .layout-wrapper.layout-static .layout-main {
    margin-left: 250px;
    transition: margin-left 0.3s;
  }
  .layout-wrapper.layout-static .layout-footer {
    transition: padding-left 0.3s;
  }
  .layout-wrapper.layout-static.layout-static-inactive .layout-topbar {
    left: 0;
    transition: left 0.3s;
  }
  .layout-wrapper.layout-static.layout-static-inactive .layout-menu-container {
    transform: translateX(-100%);
  }
  .layout-wrapper.layout-static.layout-static-inactive .layout-main {
    margin-left: 0;
  }
  .layout-wrapper.layout-static.layout-static-inactive .layout-footer {
    padding-left: 20px;
    transition: padding-left 0.3s;
  }
  .layout-wrapper.layout-static.layout-static-inactive-restore .layout-menu-container {
    transition: none;
  }
  .layout-wrapper.layout-overlay .layout-topbar {
    left: 0;
    transition: left 0.3s;
  }
  .layout-wrapper.layout-overlay .layout-menu-container {
    width: 250px;
    box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);
    transition: transform 0.3s;
  }
  .layout-wrapper.layout-overlay .layout-menu-container.layout-menu-container-inactive {
    transform: translateX(-100%);
  }
  .layout-wrapper.layout-overlay .layout-menu-container .layout-menu, .layout-wrapper.layout-overlay .layout-menu-container .layout-profile {
    border-right: 0 none;
  }
  .layout-wrapper.layout-overlay.layout-overlay-active .layout-topbar {
    left: 250px;
    transition: left 0.3s;
  }
  .layout-wrapper.layout-slim .layout-topbar {
    left: 60px;
  }
  .layout-wrapper.layout-slim .layout-topbar .layout-menu-button {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-main {
    margin-left: 60px;
  }
  .layout-wrapper.layout-slim .layout-menu-container {
    padding: 0;
    height: 100%;
    width: 60px;
    overflow: visible;
    top: 0;
    position: fixed;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu-wrapper {
    overflow: visible;
    padding-bottom: 0px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu-logo {
    overflow: hidden;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu-logo button img {
    height: 36px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-profile .layout-profile-button {
    text-align: center;
    padding: 12px 0;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-profile .layout-profile-button img {
    width: 32px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-profile .layout-profile-button .layout-profile-userinfo,
.layout-wrapper.layout-slim .layout-menu-container .layout-profile .layout-profile-button .layout-profile-icon {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-profile .layout-profile-menu {
    position: absolute;
    overflow: auto;
    top: 0;
    left: 59px;
    min-width: 250px;
    max-height: 450px;
    display: none;
    border-left: 4px solid #2196F3;
    padding: 8px 12px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-profile .layout-profile-menu:before, .layout-wrapper.layout-slim .layout-menu-container .layout-profile .layout-profile-menu:after {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    left: 210px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-profile .layout-profile-menu:before {
    border-color: rgba(216, 216, 220, 0);
    border-bottom-color: #e0e0e0;
    border-width: 10px;
    margin-left: -10px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-profile .layout-profile-menu:after {
    border-color: rgba(244, 244, 249, 0);
    border-bottom-color: #ffffff;
    border-width: 9px;
    margin-left: -9px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-profile .layout-profile-menu li button {
    padding: 8px 12px;
    transition: background-color 0.3s;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-profile.layout-profile-active .layout-profile-menu {
    display: block;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu {
    padding: 0;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu li.active-menuitem > ul {
    display: block;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li {
    position: relative;
    margin-bottom: 0;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li.active-menuitem > a,
.layout-wrapper.layout-slim .layout-menu-container .layout-menu > li.active-menuitem > .p-link {
    background-color: #2196F3;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li.active-menuitem > a .layout-menuitem-text,
.layout-wrapper.layout-slim .layout-menu-container .layout-menu > li.active-menuitem > a .layout-menuitem-icon,
.layout-wrapper.layout-slim .layout-menu-container .layout-menu > li.active-menuitem > a .layout-submenu-toggler,
.layout-wrapper.layout-slim .layout-menu-container .layout-menu > li.active-menuitem > .p-link .layout-menuitem-text,
.layout-wrapper.layout-slim .layout-menu-container .layout-menu > li.active-menuitem > .p-link .layout-menuitem-icon,
.layout-wrapper.layout-slim .layout-menu-container .layout-menu > li.active-menuitem > .p-link .layout-submenu-toggler {
    color: #ffffff;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > div {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > a,
.layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > .p-link {
    display: block;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    padding-top: 19px;
    padding-bottom: 19px;
    border-radius: 0;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > a .layout-menuitem-icon,
.layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > .p-link .layout-menuitem-icon {
    font-size: 20px;
    margin-right: 0;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > a .layout-menuitem-text, .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > a .layout-submenu-toggler,
.layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > .p-link .layout-menuitem-text,
.layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > .p-link .layout-submenu-toggler {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > a:hover + .layout-menu-tooltip,
.layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > .p-link:hover + .layout-menu-tooltip {
    display: block;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul {
    position: absolute;
    overflow: auto;
    top: 0;
    left: 59px;
    min-width: 250px;
    max-height: 450px;
    display: none;
    border-left: 4px solid #2196F3;
    padding: 8px 12px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li a,
.layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li .p-link {
    padding: 8px 12px;
    display: block;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li ul {
    width: 100%;
    box-shadow: none;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li ul li a,
.layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li ul li .p-link {
    padding-left: 28px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li ul li li a,
.layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li ul li li .p-link {
    padding-left: 44px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li ul li li li a,
.layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li ul li li li .p-link {
    padding-left: 60px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li ul li li li li a,
.layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li ul li li li li .p-link {
    padding-left: 76px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li ul li li li li li a,
.layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li ul li li li li li .p-link {
    padding-left: 92px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li ul li li li li li li a,
.layout-wrapper.layout-slim .layout-menu-container .layout-menu > li > ul li ul li li li li li li .p-link {
    padding-left: 108px;
  }
  .layout-wrapper.layout-slim .layout-menu-container .layout-menu > li.active-menuitem > a:hover + .layout-menu-tooltip,
.layout-wrapper.layout-slim .layout-menu-container .layout-menu > li.active-menuitem > .p-link:hover + .layout-menu-tooltip {
    display: none;
  }
  .layout-wrapper.layout-slim.layout-menu-light .layout-menu-container .layout-menu > li > ul {
    background-color: #ffffff;
  }
  .layout-wrapper.layout-slim.layout-menu-light .layout-menu-container .layout-profile-menu {
    background-color: #ffffff;
  }
  .layout-wrapper.layout-slim.layout-menu-dark .layout-menu-container .layout-menu > li > ul {
    background-color: #212121;
  }
  .layout-wrapper.layout-slim.layout-menu-dark .layout-menu-container .layout-profile-menu {
    background-color: #212121;
  }
  .layout-wrapper.layout-horizontal .layout-topbar {
    left: 0;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-logo {
    display: inline-block;
  }
  .layout-wrapper.layout-horizontal .layout-topbar .layout-menu-button {
    display: none;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container {
    width: 100%;
    height: 46px;
    top: 60px;
    z-index: 99;
    position: fixed;
    padding: 5px 12px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu-wrapper {
    overflow: visible;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu-logo {
    display: none;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-profile {
    border-bottom: 0 none;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 0;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-profile .layout-profile-button {
    padding: 0;
    margin-top: 2px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-profile .layout-profile-button img {
    width: 32px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-profile .layout-profile-button .layout-profile-userinfo,
.layout-wrapper.layout-horizontal .layout-menu-container .layout-profile .layout-profile-button .layout-profile-icon {
    display: none;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-profile .layout-profile-button:hover {
    background-color: transparent;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-profile .layout-profile-menu {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    right: -20px;
    top: 50px;
    min-width: 250px;
    padding: 8px 12px;
    border-radius: 3px;
    animation-duration: 0.3s;
    box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-profile .layout-profile-menu:before, .layout-wrapper.layout-horizontal .layout-menu-container .layout-profile .layout-profile-menu:after {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    left: 210px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-profile .layout-profile-menu:before {
    border-color: rgba(216, 216, 220, 0);
    border-bottom-color: #e0e0e0;
    border-width: 10px;
    margin-left: -10px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-profile .layout-profile-menu:after {
    border-color: rgba(244, 244, 249, 0);
    border-bottom-color: #ffffff;
    border-width: 9px;
    margin-left: -9px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-profile .layout-profile-menu li button {
    padding: 8px 12px;
    color: #727272;
    transition: background-color 0.3s;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-profile .layout-profile-menu li button i {
    color: #727272;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-profile .layout-profile-menu li button:hover {
    background-color: #e0e0e0;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-profile.layout-profile-active .layout-profile-menu {
    display: block;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu {
    margin: 0;
    padding: 0;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu li.active-menuitem > ul {
    display: block;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li {
    display: inline-block;
    width: auto;
    padding: 0;
    position: relative;
    margin-bottom: 0;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li.active-menuitem > a,
.layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li.active-menuitem > .p-link {
    background-color: #2196F3;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li.active-menuitem > a .layout-menuitem-text,
.layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li.active-menuitem > a .layout-menuitem-icon,
.layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li.active-menuitem > a .layout-submenu-toggler,
.layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li.active-menuitem > .p-link .layout-menuitem-text,
.layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li.active-menuitem > .p-link .layout-menuitem-icon,
.layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li.active-menuitem > .p-link .layout-submenu-toggler {
    color: #ffffff;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > div {
    display: none;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > a,
.layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > .p-link {
    display: block;
    padding: 8px 12px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > a i,
.layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > .p-link i {
    position: static;
    margin-top: 0;
    vertical-align: middle;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul {
    display: none;
    top: 33px;
    left: 0;
    min-width: 250px;
    position: absolute;
    margin: 0;
    padding: 8px 12px;
    max-height: 450px;
    overflow: auto;
    border-top: 4px solid #2196F3;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li a,
.layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li .p-link {
    padding: 8px 12px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li a .layout-menuitem-icon,
.layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li .p-link .layout-menuitem-icon {
    margin-right: 8px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li li a,
.layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li li .p-link {
    padding-left: 28px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li li li a,
.layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li li li .p-link {
    padding-left: 44px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li li li li a,
.layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li li li li .p-link {
    padding-left: 60px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li li li li li a,
.layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li li li li li .p-link {
    padding-left: 76px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li li li li li li a,
.layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li li li li li li .p-link {
    padding-left: 92px;
  }
  .layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li li li li li li li a,
.layout-wrapper.layout-horizontal .layout-menu-container .layout-menu > li > ul > li li li li li li li .p-link {
    padding-left: 108px;
  }
  .layout-wrapper.layout-horizontal .layout-main {
    padding-top: 106px;
  }
  .layout-wrapper.layout-horizontal.layout-menu-light .layout-menu-container {
    border-bottom: 1px solid #E0E0E0;
  }
  .layout-wrapper.layout-horizontal.layout-menu-light .layout-menu-container .layout-menu-wrapper {
    border-right: 0 none;
  }
  .layout-wrapper.layout-horizontal.layout-menu-light .layout-menu-container .layout-menu > li > ul {
    background-color: #ffffff;
  }
  .layout-wrapper.layout-horizontal.layout-menu-dark .layout-menu-container {
    border-bottom: 0 none;
  }
  .layout-wrapper.layout-horizontal.layout-menu-dark .layout-menu-container .layout-profile {
    border-right: 0 none;
  }
  .layout-wrapper.layout-horizontal.layout-menu-dark .layout-menu-container .layout-menu {
    border-right: 0 none;
  }
  .layout-wrapper.layout-horizontal.layout-menu-dark .layout-menu-container .layout-menu > li > ul {
    background-color: #212121;
  }
}
@media screen and (max-width: 896px) {
  .layout-wrapper .layout-topbar {
    left: 0;
  }
  .layout-wrapper .layout-menu-container {
    top: 0;
    width: 250px;
    transform: translateX(-100%);
    transition: transform 0.3s;
  }
  .layout-wrapper .layout-menu-container.layout-menu-container-inactive {
    transform: translateX(-100%) !important;
  }
  .layout-wrapper .layout-main {
    margin-left: 0;
    transition: margin-left 0.3s;
  }
  .layout-wrapper.layout-mobile-active .layout-menu-container {
    transform: translateX(0);
  }
  /* .layout-wrapper.layout-mobile-active .layout-mask {
    display: block;
  } */

  body.blocked-scroll {
    overflow: hidden;
  }
}
.layout-config {
  width: 16rem;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  padding: 1rem;
  overflow: auto;
  background: #ffffff;
  z-index: 999;
  border-left: 0 none;
  transform: translateX(100%);
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}
.layout-config.layout-config-active {
  transform: translateX(0);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.layout-config.layout-config-active .layout-config-content .layout-config-button i {
  transform: rotate(360deg);
}
.layout-config .layout-themes {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.layout-config .layout-themes > div {
  padding: 0.25rem;
}
.layout-config .layout-themes a,
.layout-config .layout-themes .p-link {
  width: 2rem;
  height: 2rem;
  border-radius: 3px;
  display: block;
  position: relative;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: transform 0.3s;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.layout-config .layout-themes a i,
.layout-config .layout-themes .p-link i {
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
  color: #ffffff;
}
.layout-config .layout-themes a:hover,
.layout-config .layout-themes .p-link:hover {
  transform: scale(1.1);
}

.layout-config-button.p-link {
  display: block;
  position: fixed;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  background: #2196F3;
  color: #ffffff;
  text-align: center;
  top: 50%;
  right: 0;
  margin-top: -1.5rem;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  transition: background-color 0.3s;
  overflow: hidden;
  cursor: pointer;
  z-index: 999;
  box-shadow: -0.25rem 0 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0;
}
.layout-config-button.p-link i {
  font-size: 2rem;
  line-height: inherit;
  transform: rotate(0deg);
  transition: transform 1s;
}
.layout-config-button.p-link:hover {
  background: #39a1f4;
}

.layout-footer {
  padding: 20px;
  background-color: #ffffff;
  border-top: 1px solid #e8e8e8;
}
.layout-footer img {
  height: 30px;
  margin-top: 4px;
}
.layout-footer .footer-icons {
  text-align: right;
}
.layout-footer .footer-icons button {
  color: #727272;
  font-size: 1.5rem;
  margin-right: 16px;
  margin-top: 6px;
  display: inline-block;
  transition: color 0.3s;
}
.layout-footer .footer-icons button:hover {
  color: #212121;
}

/* Utils */
.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

.card {
  background: #ffffff;
  padding: 1.5rem;
  margin-bottom: 1rem;
  border: 1px solid #EEEEEE;
  border-radius: 3px;
}
.card.card-w-title {
  padding-bottom: 16px;
}
.card.no-gutter {
  margin-bottom: 0;
}

.nopad {
  padding: 0;
}
.nopad .ui-panel-content {
  padding: 0;
}

.p-sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.separator {
  border: 1px solid #E0E0E0;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

.layout-wrapper .layout-ajax-loader {
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.layout-wrapper .layout-ajax-loader .layout-ajax-loader-icon {
  color: #2196F3;
  font-size: 32px;
}

::-webkit-input-placeholder {
  color: #727272;
}

:-moz-placeholder {
  color: #727272;
}

::-moz-placeholder {
  color: #727272;
}

:-ms-input-placeholder {
  color: #727272;
}

.layout-dashboard .overview-box {
  padding: 16px;
  color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}
.layout-dashboard .overview-box:after {
  content: "";
  display: table;
  clear: both;
}
.layout-dashboard .overview-box .overview-icon {
  font-size: 2em;
  float: right;
}
.layout-dashboard .overview-box .overview-title {
  margin-bottom: 8px;
  display: inline-block;
  font-size: 18px;
}
.layout-dashboard .overview-box .overview-arrow {
  color: #ffffff;
  margin-left: 8px;
  font-size: 16px;
}
.layout-dashboard .overview-box .overview-numbers {
  margin-bottom: 12px;
  font-size: 18px;
}
.layout-dashboard .overview-box .overview-subinfo {
  font-size: 13px;
  border-left: 4px solid;
  margin-left: -16px;
  padding-left: 12px;
}
.layout-dashboard .overview-box.sales {
  background-color: #42A5F5;
}
.layout-dashboard .overview-box.sales .overview-icon {
  color: #BBDEFB;
}
.layout-dashboard .overview-box.sales .overview-subinfo {
  border-color: #BBDEFB;
  color: #BBDEFB;
}
.layout-dashboard .overview-box.views {
  background-color: #272360;
}
.layout-dashboard .overview-box.views .overview-icon {
  color: #D1C4E9;
}
.layout-dashboard .overview-box.views .overview-subinfo {
  border-color: #D1C4E9;
  color: #D1C4E9;
}
.layout-dashboard .overview-box.users {
  background-color: #f7b005;
}
.layout-dashboard .overview-box.users .overview-icon {
  color: #FFECB3;
}
.layout-dashboard .overview-box.users .overview-subinfo {
  border-color: #FFECB3;
  color: #FFECB3;
}
.layout-dashboard .overview-box.checkin {
  background-color: #46a748;
}
.layout-dashboard .overview-box.checkin .overview-icon {
  color: #FFF8E1;
}
.layout-dashboard .overview-box.checkin .overview-subinfo {
  border-color: #FFF8E1;
  color: #FFF8E1;
}
.layout-dashboard .tasks {
  min-height: 300px;
}
.layout-dashboard .tasks ul {
  padding: 0;
  margin: 0;
}
.layout-dashboard .tasks ul li {
  list-style: none;
  padding: 12px;
  border-bottom: 1px solid #ebebef;
  position: relative;
}
.layout-dashboard .tasks ul li:first-child {
  padding-top: 0;
}
.layout-dashboard .tasks ul li:last-child {
  border-bottom: 0 none;
}
.layout-dashboard .tasks ul li .p-checkbox {
  margin-right: 8px;
  vertical-align: middle;
}
.layout-dashboard .tasks ul li > span {
  vertical-align: middle;
}
.layout-dashboard .tasks ul li .task-badge {
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.layout-dashboard .tasks ul li .task-badge.red {
  background-color: #E91E63;
}
.layout-dashboard .tasks ul li .task-badge.orange {
  background-color: #FF9800;
}
.layout-dashboard .tasks ul li .task-badge.green {
  background-color: #4CAF50;
}
.layout-dashboard .resolution-center {
  min-height: 300px;
}
.layout-dashboard .resolution-center label {
  display: block;
  margin-bottom: 6px;
}
label {
  font-size: small !important;
}
.layout-dashboard .resolution-center .p-inputtext {
  width: 100%;
  margin-bottom: 16px;
}
.layout-dashboard .resolution-center .p-inputtextarea {
  width: 100%;
  margin-bottom: 16px;
}
.layout-dashboard .resolution-center .resolution-button-bar {
  text-align: right;
  margin-top: 16px;
}
.layout-dashboard .resolution-center .resolution-button-bar .p-button {
  width: auto;
  margin-left: 12px;
  min-width: 100px;
}
.layout-dashboard .team {
  min-height: 300px;
}
.layout-dashboard .team ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.layout-dashboard .team ul li {
  padding: 10px;
  border-bottom: 1px solid #eeeeee;
}
.layout-dashboard .team ul li:after {
  content: "";
  display: table;
  clear: both;
}
.layout-dashboard .team ul li:first-child {
  padding-top: 0;
}
.layout-dashboard .team ul li:last-child {
  border-bottom: 0 none;
}
.layout-dashboard .team ul li:nth-child(1) .team-member-role {
  background-color: #2196F3;
  color: #ffffff;
}
.layout-dashboard .team ul li:nth-child(2) .team-member-role {
  background-color: #4CAF50;
  color: #ffffff;
}
.layout-dashboard .team ul li:nth-child(3) .team-member-role {
  background-color: #F7B005;
  color: #ffffff;
}
.layout-dashboard .team ul li:nth-child(4) .team-member-role {
  background-color: #9C27B0;
  color: #ffffff;
}
.layout-dashboard .team ul li img {
  width: 35px;
  height: 35px;
  vertical-align: middle;
  display: inline-block;
}
.layout-dashboard .team ul li .team-box {
  margin-left: 8px;
  display: inline-block;
  vertical-align: middle;
}
.layout-dashboard .team ul li .team-box .team-member {
  text-align: left;
  display: block;
  font-weight: 600;
  color: #252529;
  font-size: 14px;
}
.layout-dashboard .team ul li .team-box .team-member-role {
  border-radius: 4px;
  padding: 4px;
  font-size: 11px;
}
.layout-dashboard-li-button {
  float: right;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  line-height: 30px;
  margin-left: 6px;
  text-align: center;
  margin-top: 4px;
}
.layout-dashboard-li-button i {
  line-height: inherit;
  font-size: 18px;
  color: #525257;
}
.layout-dashboard-li-button:focus {
  box-shadow: 0 0 0 0 #ffffff;
}
.layout-dashboard .statistics h1 {
  margin-top: 0;
}
.layout-dashboard .user-card {
  padding: 0;
}
.layout-dashboard .user-card .user-card-header {
  height: 100px;
  overflow: hidden;
  background: #ffe0ee;
}
.layout-dashboard .user-card .user-card-header img {
  width: 100%;
  height: 100%;
}
.layout-dashboard .user-card .user-card-content img {
  width: 60px;
  height: 60px;
  margin: -30px 0 0 24px;
}
.layout-dashboard .user-card .user-card-content .p-button {
  float: right;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin: -20px 24px 0 0;
}
.layout-dashboard .user-card .user-card-content .user-card-name {
  font-size: 20px;
  color: #ffffff;
  position: relative;
  top: -60px;
  margin-left: 100px;
  font-weight: 700;
  white-space: nowrap;
}
.layout-dashboard .user-card .user-card-content .user-detail {
  text-align: left;
}
.layout-dashboard .user-card .user-card-content .user-detail ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li {
  padding: 16px 24px;
  border-bottom: 1px solid #e0e0e0;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li:after {
  content: "";
  display: table;
  clear: both;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li:last-child {
  border-bottom: 0 none;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li i {
  font-size: 20px;
  margin-right: 8px;
  width: 32px;
  vertical-align: middle;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li .project-title {
  font-weight: 700;
  margin-right: 8px;
  vertical-align: middle;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li .project-detail {
  color: #727272;
  vertical-align: middle;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li .project-progressbar {
  width: 100px;
  background-color: #e0e0e0;
  float: right;
  margin-top: 12px;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li .project-progressbar .project-progressbar-value {
  height: 4px;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li:nth-child(1) .project-progressbar-value {
  background-color: #009688;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li:nth-child(1) i {
  color: #009688;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li:nth-child(2) .project-progressbar-value {
  background-color: #4CAF50;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li:nth-child(2) i {
  color: #4CAF50;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li:nth-child(3) .project-progressbar-value {
  background-color: #9C27B0;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li:nth-child(3) i {
  color: #9C27B0;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li:nth-child(4) .project-progressbar-value {
  background-color: #F7B005;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li:nth-child(4) i {
  color: #F7B005;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li:nth-child(5) .project-progressbar-value {
  background-color: #00BCD4;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li:nth-child(5) i {
  color: #00BCD4;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li:nth-child(6) .project-progressbar-value {
  background-color: #607D8B;
}
.layout-dashboard .user-card .user-card-content .user-detail ul li:nth-child(6) i {
  color: #607D8B;
}
.layout-dashboard .timeline ul {
  padding: 0;
  margin: 0;
}
.layout-dashboard .timeline ul li {
  list-style: none;
  padding: 22px 0px;
  position: relative;
}
.layout-dashboard .timeline ul li:nth-child(1) .timeline-icon {
  background-color: #673AB7;
}
.layout-dashboard .timeline ul li:nth-child(2) .timeline-icon {
  background-color: #9C27B0;
}
.layout-dashboard .timeline ul li:nth-child(3) .timeline-icon {
  background-color: #2196F3;
}
.layout-dashboard .timeline ul li:nth-child(4) .timeline-icon {
  background-color: #009688;
}
.layout-dashboard .timeline ul li .activity-link {
  width: 1px;
  height: 100%;
  position: absolute;
  left: 20px;
  top: 0;
  background-color: #e0e0e0;
  display: inline-block;
}
.layout-dashboard .timeline ul li .timeline-icon {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 20px;
  left: 8px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 50%;
}
.layout-dashboard .timeline ul li .timeline-icon i {
  font-size: 14px;
  color: #ffffff;
  line-height: inherit;
}
.layout-dashboard .timeline ul li .timeline-content {
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  padding: 16px;
  margin-left: 48px;
  margin-top: -4px;
  position: relative;
  border-radius: 3px;
}
.layout-dashboard .timeline ul li .timeline-content h3 {
  color: #212121;
  font-size: 14px;
  margin: 0;
  font-weight: 700;
}
.layout-dashboard .timeline ul li .timeline-content p {
  margin: 8px 0;
  color: #727272;
}
.layout-dashboard .timeline ul li .timeline-content img {
  margin: 8px 4px;
}
.layout-dashboard .timeline ul li .timeline-content .timeline-footer {
  margin: 0 -16px -16px -16px;
  background-color: #eeeeee;
  border-top: 1px solid #e0e0e0;
  padding: 4px 16px;
}
.layout-dashboard .timeline ul li .timeline-content .timeline-footer i {
  color: #757575;
}
.layout-dashboard .timeline ul li .timeline-content .timeline-footer span {
  color: #757575;
  margin-left: 8px;
}
.layout-dashboard .timeline ul li .timeline-content:before, .layout-dashboard .timeline ul li .timeline-content:after {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  top: 16px;
}
.layout-dashboard .timeline ul li .timeline-content:before {
  border-color: rgba(216, 216, 220, 0);
  border-right-color: #e0e0e0;
  border-width: 10px;
  margin-top: -10px;
}
.layout-dashboard .timeline ul li .timeline-content:after {
  border-color: rgba(244, 244, 249, 0);
  border-right-color: #ffffff;
  border-width: 9px;
  margin-top: -9px;
}
.layout-dashboard .global-sales .p-datatable th {
  border: 0 none;
  text-align: left;
}
.layout-dashboard .global-sales .p-datatable tr {
  border: 0 none;
}
.layout-dashboard .global-sales .p-datatable tr td {
  border: 0 none;
}
.layout-dashboard .global-sales .p-datatable tr.p-state-highlight, .layout-dashboard .global-sales .p-datatable tr.p-state-hover {
  border: 0 none;
}
.layout-dashboard .global-sales .p-datatable .p-paginator {
  border: 0 none;
}
.layout-dashboard .global-sales .p-datatable button:last-child {
  margin-left: 6px;
}
.layout-dashboard .live-support ul {
  padding: 12px;
  margin: 0;
  list-style-type: none;
}
.layout-dashboard .live-support ul li {
  padding: 12px;
}
.layout-dashboard .live-support ul li .col-fixed {
  width: 48px;
}
.layout-dashboard .live-support ul li img {
  width: 36px;
}
.layout-dashboard .live-support ul li .chat-message {
  position: relative;
  padding: 8px;
  color: #ffffff;
  border-radius: 3px;
}
.layout-dashboard .live-support ul li:nth-child(1) .chat-message {
  background-color: #2196F3;
}
.layout-dashboard .live-support ul li:nth-child(1) .chat-message:before {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  top: 16px;
}
.layout-dashboard .live-support ul li:nth-child(1) .chat-message:before {
  border-right-color: #2196F3;
  border-width: 10px;
  margin-top: -10px;
}
.layout-dashboard .live-support ul li:nth-child(2) .chat-message {
  background-color: #F7B005;
  color: #212121;
}
.layout-dashboard .live-support ul li:nth-child(2) .chat-message:before {
  left: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  top: 16px;
}
.layout-dashboard .live-support ul li:nth-child(2) .chat-message:before {
  border-left-color: #F7B005;
  border-width: 10px;
  margin-top: -10px;
}
.layout-dashboard .live-support ul li:nth-child(3) .chat-message {
  background-color: #673AB7;
}
.layout-dashboard .live-support ul li:nth-child(3) .chat-message:before {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  top: 16px;
}
.layout-dashboard .live-support ul li:nth-child(3) .chat-message:before {
  border-right-color: #673AB7;
  border-width: 10px;
  margin-top: -10px;
}
.layout-dashboard .live-support .new-message {
  border: 1px solid #ebebef;
  border-right: 0 none;
}
.layout-dashboard .live-support .new-message input {
  border: 0 none;
  font-size: 14px;
  width: 100%;
  padding: 0.5em;
  background-color: transparent;
  outline: 0 none;
  color: #727272;
  font-family: "Poppins", sans-serif;
}
.layout-dashboard .live-support .new-message .col-fixed {
  width: 100px;
}
.layout-dashboard .live-support .new-message .col-fixed .p-button {
  width: 100px;
  border-radius: 0;
}
.layout-dashboard .p-datatable-products .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}
@media (max-width: 960px) {
  .layout-dashboard .p-datatable.p-datatable-products .p-datatable-thead > tr > th,
.layout-dashboard .p-datatable.p-datatable-products .p-datatable-tfoot > tr > td {
    display: none !important;
  }
  .layout-dashboard .p-datatable.p-datatable-products .p-datatable-tbody > tr {
    border-bottom: 1px solid var(--surface-d);
  }
  .layout-dashboard .p-datatable.p-datatable-products .p-datatable-tbody > tr > td {
    text-align: left;
    display: flex;
    border: 0 none !important;
    width: 100% !important;
    border: 0 none;
    align-items: center;
  }
  .layout-dashboard .p-datatable.p-datatable-products .p-datatable-tbody > tr > td .p-column-title {
    min-width: 30%;
    display: block;
    font-weight: bold;
  }
}

.exception-body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
}
.exception-body .exception-panel {
  display: none;
}
.exception-body .exception-content {
  position: fixed;
  padding: 80px 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;
}
.exception-body .exception-content img {
  display: inline-block;
  margin-bottom: 160px;
  width: 200px;
}
.exception-body .exception-content h1 {
  font-size: 28px;
  font-weight: 500;
  margin: 14px 0;
  color: #212121;
}
.exception-body .exception-content h1 .exception-name {
  color: #727272;
}
.exception-body .exception-content p {
  font-size: 17px;
  color: #727272;
  margin: 0 0 32px 0;
}

@media screen and (min-width: 768px) {
  .exception-body .exception-panel {
    display: block;
    position: fixed;
    background-color: #ffffff;
    height: 200%;
    width: 100%;
    left: -50%;
    top: -50%;
    opacity: 0.95;
    filter: alpha(opacity=95);
    border-radius: 50%;
  }
  .exception-body .exception-content {
    text-align: left;
    position: fixed;
    padding: 80px 0 0 80px;
  }
  .exception-body.error {
    background: url("../images/pages/bg-error.jpg");
    background-size: cover;
  }
  .exception-body.notfound {
    background: url("../images/pages/bg-notfound.jpg");
    background-size: cover;
  }
  .exception-body.access-denied {
    background: url("../images/pages/bg-access.jpg");
    background-size: cover;
  }
}
.login-body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
}
.login-body .login-panel {
  display: none;
}
.login-body .login-content {
  padding: 80px 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;
}
.login-body .login-content img {
  display: inline-block;
  margin-bottom: 160px;
  width: 200px;
}
.login-body .login-content h1 {
  font-size: 28px;
  font-weight: 500;
  margin: 14px 0;
  color: #212121;
}
.login-body .login-content h1 span {
  color: #727272;
}
.login-body .login-content p {
  font-size: 17px;
  color: #727272;
  margin: 0 0 32px 0;
}
.login-body .login-content .login-input-wrapper {
  position: relative;
  width: 300px;
  margin-bottom: 24px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.login-body .login-content .login-input-wrapper input {
  width: 100%;
  padding-right: 16px;
}
.login-body .login-content .login-input-wrapper i {
  position: absolute;
  color: #727272;
  font-size: 16px;
  top: 50%;
  right: 0.429em;
  margin-top: -8px;
}

@media screen and (min-width: 768px) {
  .login-body {
    background: url("../images/pages/bg-login.jpg");
    background-size: cover;
  }
  .login-body .login-panel {
    padding: 80px;
    display: block;
    position: fixed;
    background-color: #ffffff;
    height: 200%;
    width: 100%;
    left: -50%;
    top: -50%;
    border-radius: 50%;
    opacity: 0.95;
    filter: alpha(opacity=95);
  }
  .login-body .login-content {
    text-align: left;
    position: fixed;
    padding: 80px 0 0 80px;
  }
  .login-body .login-content .login-input-wrapper {
    width: 300px;
    margin-left: 0;
    margin-right: 0;
    text-align: left;
  }
}
.landing-body .layout-mask {
  display: block;
}
.landing-body .landing-wrapper {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.landing-body .landing-wrapper #header {
  background: linear-gradient(to right, #191660 0%,#181660e7 30%, #181660e7 70%, #191660 100%);
}
.landing-body .landing-wrapper #landing-menu {
  list-style-type: none;
  float: right;
  padding: 0;
  margin: 14px 0 0 0;
  display: flex;
}
.landing-body .landing-wrapper #landing-menu li {
  display: inline-block;
  margin-left: 14px;
}
.landing-body .landing-wrapper #landing-menu li #shop-icon {
    color: var(--green-100) !important;
}
.landing-body .landing-wrapper #landing-menu li:first-child {
  margin-left: 0;
}
.landing-body .landing-wrapper #landing-menu li a {
  font-size: 14px;
  font-weight: 600;
  color: #525257;
  padding: 4px 8px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.landing-body .landing-wrapper #landing-menu li a:hover {
  background-color: #252529;
  color: #ffffff;
}
.landing-body .landing-wrapper #header .header-top {
  padding: 20px 40px 0px 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}
.landing-body .landing-wrapper #header .header-top .landing-menu-center {
    justify-self: center;
}
.landing-body .landing-wrapper #header .header-top .logo {
  height: 50px;
}
.landing-body .landing-wrapper #header .header-top #landing-menu-button {
  display: none;
  color: #000000;
  float: right;
  margin-top: 14px;
  text-align: center;
}
.landing-body .landing-wrapper #header .header-top #landing-menu-button img {
  width: 6px;
  margin-right: 10px;
}
.landing-body .landing-wrapper #header .header-content {
  padding: 30px !important;
  box-shadow: inset 0 -350px var(--surface-a);
}
.landing-body .landing-wrapper #header .header-content h1 {
  font-size: 42px;
  font-weight: 700;
  color: #ffffff;
  margin-top: 48px;
  margin-bottom: 24px;
  max-width: 600px;
}
.landing-body .landing-wrapper #header .header-content p {
  font-size: 17px;
  margin-bottom: 24px;
  color: #8b8b90;
}
.landing-body .landing-wrapper #features {
  padding: 60px 140px;
}
.landing-body .landing-wrapper #benefits, #extracoinSection {
  padding: 42px 140px;
}
.landing-body .landing-wrapper #promo {
  padding: 42px 140px;
}
.landing-body .landing-wrapper #promo h1 {
  margin: 0 0 16px 0;
}
.landing-body .landing-wrapper #promo p {
  margin: 0 0 16px 0;
  width: 75%;
}
.landing-body .landing-wrapper #pricing {
  padding: 42px 140px;
}
.landing-body .landing-wrapper #pricing .grid .col-12 {
  padding: 20px;
}
.landing-body .landing-wrapper #pricing .grid .border-bottom {
  border-bottom: 2px solid var(--green-600);
}
.landing-body .landing-wrapper #pricing .pricing-box {
  min-height: 550px;
  padding: 16px 48px;
  background-color: #f5f5f5;
  color: #525257;
  border: 2px solid transparent;
  -moz-transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
}
.landing-body .landing-wrapper #pricing .pricing-box h3 {
  margin: 0 0 42px 0;
}
.landing-body .landing-wrapper #pricing .pricing-box p {
  margin: 14px 0;
}
.landing-body .landing-wrapper #pricing .pricing-box:hover {
  border-color: #2196F3;
}
.landing-body .landing-wrapper #promo .video-container iframe {
  width: 560px;
  height: 315px;
}
.gridless {
  display: none !important;
}

@media screen and (min-width: 992px) {
  .landing-body .landing-wrapper #header .header-content {
    background-position: right 50%;
    background-size: 35%;
  }
}
@media screen and (max-width: 1200px) {
  .grid-landing {
    display: none !important;
  }

  .landing-body .landing-wrapper #pricing .pricing-box {
    padding: 16px 24px;
  }

  .gridless {
    display: block !important;
  }

  .landing-body.landing-menu-active .landing-wrapper #landing-menu {
    right: 0;
    display: block;
  }
  .landing-body .landing-wrapper #header .header-top {
    padding: 10px 40px 10px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .landing-body.landing-menu-active .landing-wrapper #landing-menu button {
    font-size: 1.5em;
    margin-left: 22px;
  }
  .button-li {
    padding-top: 12px;
  }
  .landing-body .landing-wrapper #landing-menu {
    width: 250px;
    height: 100%;
    right: -250px;
    background-color: #252529;
    position: fixed;
    top: 0;
    margin: 0;
    z-index: 999;
    -moz-transition: right 0.3s;
    -o-transition: right 0.3s;
    -webkit-transition: right 0.3s;
    transition: right 0.3s;
  }
  .landing-body .landing-wrapper #landing-menu > li {
    display: block;
    margin: 0;
  }

  .mobile-a {
    font-size: 18px;
    display: block;
    color: #8b8b90;
    padding: 12px 24px;
    background-color: initial !important;
  }

  .landing-body .landing-wrapper #header .header-top {
    padding-left: 20px;
    padding-right: 20px;
  }
  .landing-body .landing-wrapper #header .header-top #landing-menu-button {
    display: inline-block;
  }
  .landing-body .landing-wrapper #header .header-content {
    padding-left: 20px;
    background-position: right 50%;
    box-shadow: none;
  }
  .landing-body .landing-wrapper #features {
    padding-left: 20px;
    padding-right: 20px;
  }
  .landing-body .landing-wrapper #benefits, #extracoinSection {
    padding-left: 20px;
    padding-right: 20px;
  }
  .landing-body .landing-wrapper #promo {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
  .landing-body .landing-wrapper #promo p {
    width: 100%;
  }
  .landing-body .landing-wrapper #pricing {
    padding-left: 20px;
    padding-right: 20px;
  }
  .landing-body .landing-wrapper #promo .video-container iframe {
    width: auto;
    height: auto;
  }
}
@media screen and (max-width: 575px) {
  .landing-body .landing-wrapper #header .header-content h1 {
    font-size: 21px;
  }
  .landing-body .landing-wrapper #header .header-content p {
    font-size: 16px;
    max-width: 250px;
  }
}
.widget-overview-box {
  padding: 16px;
  color: #ffffff;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}
.widget-overview-box:after {
  content: "";
  display: table;
  clear: both;
}
.widget-overview-box .overview-icon {
  font-size: 28px;
  float: right;
}
.widget-overview-box .overview-title {
  margin-bottom: 8px;
  display: inline-block;
  font-size: 18px;
}
.widget-overview-box .overview-arrow {
  color: #ffffff;
  margin-left: 8px;
  font-size: 16px;
}
.widget-overview-box .overview-numbers {
  margin-bottom: 12px;
  font-size: 18px;
}
.widget-overview-box .overview-subinfo {
  font-size: 13px;
  border-left: 4px solid;
  margin-left: -16px;
  padding-left: 12px;
}
.widget-overview-box.sales {
  background-color: #42A5F5;
}
.widget-overview-box.sales .overview-icon {
  color: #BBDEFB;
}
.widget-overview-box.sales .overview-subinfo {
  border-color: #BBDEFB;
  color: #BBDEFB;
}
.widget-overview-box.views {
  background-color: #272360;
}
.widget-overview-box.views .overview-icon {
  color: #D1C4E9;
}
.widget-overview-box.views .overview-subinfo {
  border-color: #D1C4E9;
  color: #D1C4E9;
}
.widget-overview-box.users {
  background-color: #f7b005;
}
.widget-overview-box.users .overview-icon {
  color: #FFECB3;
}
.widget-overview-box.users .overview-subinfo {
  border-color: #FFECB3;
  color: #FFECB3;
}
.widget-overview-box.checkin {
  background-color: #46a748;
}
.widget-overview-box.checkin .overview-icon {
  color: #FFF8E1;
}
.widget-overview-box.checkin .overview-subinfo {
  border-color: #FFF8E1;
  color: #FFF8E1;
}

.widget-timeline ul {
  padding: 0;
  margin: 0;
}
.widget-timeline ul li {
  list-style: none;
  padding: 22px 0px;
  position: relative;
}
.widget-timeline ul li:nth-child(1) .timeline-icon {
  background-color: #673AB7;
}
.widget-timeline ul li:nth-child(2) .timeline-icon {
  background-color: #9C27B0;
}
.widget-timeline ul li:nth-child(3) .timeline-icon {
  background-color: #2196F3;
}
.widget-timeline ul li:nth-child(4) .timeline-icon {
  background-color: #009688;
}
.widget-timeline ul li .activity-link {
  width: 1px;
  height: 100%;
  position: absolute;
  left: 20px;
  top: 0;
  background-color: #e0e0e0;
  display: inline-block;
}
.widget-timeline ul li .timeline-icon {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 20px;
  left: 10px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.widget-timeline ul li .timeline-icon i {
  font-size: 14px;
  color: #ffffff;
  line-height: inherit;
}
.widget-timeline ul li .timeline-content {
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  padding: 16px;
  margin-left: 48px;
  margin-top: -4px;
  position: relative;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.widget-timeline ul li .timeline-content h3 {
  color: #212121;
  font-size: 14px;
  margin: 0;
  font-weight: 700;
}
.widget-timeline ul li .timeline-content p {
  margin: 8px 0;
  color: #727272;
}
.widget-timeline ul li .timeline-content img {
  margin: 8px 4px;
}
.widget-timeline ul li .timeline-content .timeline-footer {
  margin: 0 -16px -16px -16px;
  background-color: #eeeeee;
  border-top: 1px solid #e0e0e0;
  padding: 4px 16px;
}
.widget-timeline ul li .timeline-content .timeline-footer i {
  color: #757575;
}
.widget-timeline ul li .timeline-content .timeline-footer span {
  color: #757575;
  margin-left: 8px;
}
.widget-timeline ul li .timeline-content:before, .widget-timeline ul li .timeline-content:after {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  top: 16px;
}
.widget-timeline ul li .timeline-content:before {
  border-color: rgba(216, 216, 220, 0);
  border-right-color: #e0e0e0;
  border-width: 10px;
  margin-top: -10px;
}
.widget-timeline ul li .timeline-content:after {
  border-color: rgba(244, 244, 249, 0);
  border-right-color: #ffffff;
  border-width: 9px;
  margin-top: -9px;
}

.widget-tasks {
  min-height: 300px;
}
.widget-tasks ul {
  padding: 0;
  margin: 0;
}
.widget-tasks ul li {
  list-style: none;
  padding: 12px;
  border-bottom: 1px solid #ebebef;
  position: relative;
}
.widget-tasks ul li:first-child {
  padding-top: 0;
}
.widget-tasks ul li:last-child {
  border-bottom: 0 none;
}
.widget-tasks ul li .p-checkbox {
  margin-right: 8px;
  vertical-align: middle;
}
.widget-tasks ul li > span {
  vertical-align: middle;
}
.widget-tasks ul li .task-badge {
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -4px;
  width: 12px;
  height: 12px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.widget-tasks ul li .task-badge.red {
  background-color: #E91E63;
}
.widget-tasks ul li .task-badge.orange {
  background-color: #FF9800;
}
.widget-tasks ul li .task-badge.green {
  background-color: #4CAF50;
}

.widget-team {
  min-height: 300px;
}
.widget-team ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-team ul li {
  padding: 10px;
  border-bottom: 1px solid #eeeeee;
}
.widget-team ul li:after {
  content: "";
  display: table;
  clear: both;
}
.widget-team ul li:first-child {
  padding-top: 0;
}
.widget-team ul li:last-child {
  border-bottom: 0 none;
}
.widget-team ul li:nth-child(1) .team-member-role {
  background-color: #2196F3;
  color: #ffffff;
}
.widget-team ul li:nth-child(2) .team-member-role {
  background-color: #4CAF50;
  color: #ffffff;
}
.widget-team ul li:nth-child(3) .team-member-role {
  background-color: #F7B005;
  color: #212121;
}
.widget-team ul li:nth-child(4) .team-member-role {
  background-color: #9C27B0;
  color: #ffffff;
}
.widget-team ul li img {
  width: 35px;
  height: 35px;
  vertical-align: middle;
  display: inline-block;
}
.widget-team ul li .team-box {
  margin-left: 8px;
  display: inline-block;
  vertical-align: middle;
}
.widget-team ul li .team-box .team-member {
  text-align: left;
  display: block;
  font-weight: 600;
  color: #252529;
  font-size: 14px;
}
.widget-team ul li .team-box .team-member-role {
  border-radius: 4px;
  padding: 4px;
  font-size: 11px;
}
.widget-team ul li a,
.widget-team ul li .p-link {
  float: right;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  line-height: 30px;
  margin-left: 6px;
  text-align: center;
  margin-top: 4px;
}
.widget-team ul li a i,
.widget-team ul li .p-link i {
  line-height: inherit;
  font-size: 18px;
  color: #525257;
}

.widget-live-support ul {
  padding: 12px;
  margin: 0;
  list-style-type: none;
}
.widget-live-support ul li {
  padding: 12px;
}
.widget-live-support ul li .col-fixed {
  width: 48px;
}
.widget-live-support ul li img {
  width: 36px;
}
.widget-live-support ul li .chat-message {
  position: relative;
  padding: 8px;
  color: #ffffff;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.widget-live-support ul li:nth-child(1n) .chat-message {
  background-color: #607D8B;
}
.widget-live-support ul li:nth-child(1n) .chat-message:before {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  top: 16px;
}
.widget-live-support ul li:nth-child(1n) .chat-message:before {
  border-right-color: #607D8B;
  border-width: 10px;
  margin-top: -10px;
}
.widget-live-support ul li:nth-child(2n) .chat-message {
  background-color: #f5f5f5;
  color: #495057;
}
.widget-live-support ul li:nth-child(2n) .chat-message:before {
  left: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  top: 16px;
}
.widget-live-support ul li:nth-child(2n) .chat-message:before {
  border-left-color: #f5f5f5;
  border-width: 10px;
  margin-top: -10px;
}
.widget-live-support .new-message {
  border: 1px solid #ebebef;
  border-right: 0 none;
}
.widget-live-support .new-message input {
  border: 0 none;
  font-size: 14px;
  width: 100%;
  padding: 0.5em;
  background-color: transparent;
  outline: 0 none;
  color: #727272;
  font-family: "Poppins", sans-serif;
}
.widget-live-support .new-message .col-fixed {
  width: 100px;
}
.widget-live-support .new-message .col-fixed .p-button {
  width: 100px;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.widget-user-card {
  padding: 0;
}
.widget-user-card .user-card-header {
  height: 100px;
  overflow: hidden;
  background: #ffe0ee;
}
.widget-user-card .user-card-header img {
  width: 100%;
  height: 100%;
}
.widget-user-card .user-card-content img {
  width: 60px;
  height: 60px;
  margin: -30px 0 0 24px;
}
.widget-user-card .user-card-content .p-button {
  float: right;
  width: 36px;
  height: 36px;
  margin: -20px 24px 0 0;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.widget-user-card .user-card-content .user-card-name {
  font-size: 20px;
  color: #ffffff;
  position: relative;
  top: -60px;
  margin-left: 100px;
  font-weight: 700;
  white-space: nowrap;
}
.widget-user-card .user-card-content .user-detail {
  text-align: left;
}
.widget-user-card .user-card-content .user-detail ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.widget-user-card .user-card-content .user-detail ul li {
  padding: 16px 24px;
  border-bottom: 1px solid #e0e0e0;
}
.widget-user-card .user-card-content .user-detail ul li:after {
  content: "";
  display: table;
  clear: both;
}
.widget-user-card .user-card-content .user-detail ul li:last-child {
  border-bottom: 0 none;
}
.widget-user-card .user-card-content .user-detail ul li i {
  font-size: 24px;
  margin-right: 8px;
  width: 32px;
  vertical-align: middle;
}
.widget-user-card .user-card-content .user-detail ul li .project-title {
  font-weight: 700;
  margin-right: 8px;
  vertical-align: middle;
}
.widget-user-card .user-card-content .user-detail ul li .project-detail {
  color: #727272;
  vertical-align: middle;
}
.widget-user-card .user-card-content .user-detail ul li .project-progressbar {
  width: 100px;
  background-color: #e0e0e0;
  float: right;
  margin-top: 12px;
}
.widget-user-card .user-card-content .user-detail ul li .project-progressbar .project-progressbar-value {
  height: 4px;
}
.widget-user-card .user-card-content .user-detail ul li:nth-child(1) .project-progressbar-value {
  background-color: #009688;
}
.widget-user-card .user-card-content .user-detail ul li:nth-child(1) i {
  color: #009688;
}
.widget-user-card .user-card-content .user-detail ul li:nth-child(2) .project-progressbar-value {
  background-color: #4CAF50;
}
.widget-user-card .user-card-content .user-detail ul li:nth-child(2) i {
  color: #4CAF50;
}
.widget-user-card .user-card-content .user-detail ul li:nth-child(3) .project-progressbar-value {
  background-color: #9C27B0;
}
.widget-user-card .user-card-content .user-detail ul li:nth-child(3) i {
  color: #9C27B0;
}
.widget-user-card .user-card-content .user-detail ul li:nth-child(4) .project-progressbar-value {
  background-color: #F7B005;
}
.widget-user-card .user-card-content .user-detail ul li:nth-child(4) i {
  color: #F7B005;
}
.widget-user-card .user-card-content .user-detail ul li:nth-child(5) .project-progressbar-value {
  background-color: #00BCD4;
}
.widget-user-card .user-card-content .user-detail ul li:nth-child(5) i {
  color: #00BCD4;
}
.widget-user-card .user-card-content .user-detail ul li:nth-child(6) .project-progressbar-value {
  background-color: #607D8B;
}
.widget-user-card .user-card-content .user-detail ul li:nth-child(6) i {
  color: #607D8B;
}

.widget-resolution-center {
  min-height: 300px;
}
.widget-resolution-center label {
  display: block;
  margin-bottom: 6px;
}
.widget-resolution-center .p-inputtext {
  width: 100%;
  margin-bottom: 16px;
}
.widget-resolution-center .p-inputtextarea {
  width: 100%;
  margin-bottom: 16px;
}
.widget-resolution-center .resolution-button-bar {
  text-align: right;
  margin-top: 16px;
}
.widget-resolution-center .resolution-button-bar .p-button {
  width: auto;
  margin-left: 12px;
  min-width: 100px;
}

.widget-image-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.widget-image-list li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #E0E0E0;
  padding: 1rem;
}
.widget-image-list li:first-child {
  border-top: 0 none;
  font-weight: 600;
  font-size: 1rem;
}
.widget-image-list li img {
  width: 64px;
  vertical-align: middle;
  margin-right: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.widget-image-list li .listitem-value {
  font-weight: 600;
}

.widget-pricing-box {
  text-align: center;
  min-height: 450px;
  padding: 16px 48px;
  background-color: #ffffff;
  color: #525257;
  border: 2px solid transparent;
  -moz-transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
}
.widget-pricing-box h3 {
  margin: 0 0 42px 0;
}
.widget-pricing-box .pricing-intro {
  color: #8b8b90;
}
.widget-pricing-box p {
  font-size: 14px;
  margin: 14px 0;
}
.widget-pricing-box ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-pricing-box ul li {
  padding: 12px;
}
.widget-pricing-box ul li i {
  color: #1fc4ac;
}
.widget-pricing-box:hover {
  border-color: #2196F3;
}

.help-wrapper .help-header {
  background: url("../images/extensions/rectangle.jpg") bottom;
  background-repeat: no-repeat;
  padding: 84px 0px 130px 0;
  display: flex;
  justify-content: center;
}
.help-wrapper .help-header .header-content {
  width: 50%;
  text-align: center;
}
.help-wrapper .help-header .header-content > h1 {
  color: #3e4243;
  font-size: 21px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 25px;
}
.help-wrapper .help-header .header-content .search-form {
  position: relative;
}
.help-wrapper .help-header .header-content .search-form span {
  width: 100%;
}
.help-wrapper .help-header .header-content .search-form span .p-autocomplete-input {
  width: 100%;
  padding: 10px;
  padding-left: 30px;
}
.help-wrapper .help-header .header-content .search-form span .p-autocomplete-input::placeholder {
  color: #8b8b90;
}
.help-wrapper .help-header .header-content .search-form > i {
  position: absolute;
  left: 10px;
  top: 11px;
  color: #8b8b90;
  font-size: 16px;
}
.help-wrapper .help-container {
  padding: 25px;
}
.help-wrapper .help-container .help-content-header {
  display: flex;
  justify-content: space-between;
}
.help-wrapper .help-container .help-content-header .help-content-header-tab {
  width: 100%;
  margin-right: 25px;
  margin-bottom: 25px;
  height: 100%;
  border-radius: 3px;
  border: solid 1px #ebebef;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 25px 15px;
  height: 100%;
  cursor: pointer;
}
.help-wrapper .help-container .help-content-header .help-content-header-tab:last-child {
  margin-right: 0;
}
.help-wrapper .help-container .help-content-header .help-content-header-tab .tab-icon {
  min-width: 50px;
  font-size: 36px;
  color: #ffffff;
  width: 50px;
  height: 50px;
  background-color: #2196F3;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}
.help-wrapper .help-container .help-content-header .help-content-header-tab .tab-content > h3 {
  font-size: 16px;
  font-weight: 600;
  color: #3e4243;
  margin: 0;
  margin-bottom: 6px;
}
.help-wrapper .help-container .help-content-header .help-content-header-tab .tab-content > span {
  font-size: 13px;
  color: #8b8b90;
}
.help-wrapper .help-container .help-content-header .help-content-header-tab.selected-tab {
  border: solid 1px #2196F3;
  background-color: #fbfdff;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.help-wrapper .help-container .help-content-header .help-content-header-tab.selected-tab .tab-icon {
  color: #2196F3;
  background-color: #ffffff;
}
.help-wrapper .help-container .help-content-header .help-content-header-tab:hover {
  background-color: #fbfdff;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.help-wrapper .help-container .help-contents-wrapper {
  overflow: hidden;
}
.help-wrapper .help-container .help-contents-wrapper .help-contents-row {
  display: flex;
  flex-direction: row;
  transition: transform 500ms ease 0s;
}
.help-wrapper .help-container .help-contents-wrapper .help-contents-row .help-content {
  flex: 1 0 100%;
  border-radius: 3px;
  border: solid 1px #ebebef;
  -moz-transition: transform 500ms ease 0s;
  -o-transition: transform 500ms ease 0s;
  -webkit-transition: transform 500ms ease 0s;
  transition: transform 500ms ease 0s;
}
.help-wrapper .help-container .help-contents-wrapper .help-contents-row .help-content .questions-header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}
.help-wrapper .help-container .help-contents-wrapper .help-contents-row .help-content .questions-header h1 {
  font-size: 21px;
  font-weight: 600;
  color: #3e4243;
  margin: 0;
  margin-bottom: 8px;
}
.help-wrapper .help-container .help-contents-wrapper .help-contents-row .help-content .questions-header span {
  font-size: 16px;
  color: #8b8b90;
}
.help-wrapper .help-container .help-contents-wrapper .help-contents-row .help-content .questions-header .profile-images {
  margin-top: 8px;
  padding: 0 40px 17px 40px;
  border-bottom: 1px solid rgba(41, 43, 44, 0.1);
}
.help-wrapper .help-container .help-contents-wrapper .help-contents-row .help-content .questions-header .profile-images img {
  width: 40px;
  margin-right: 4px;
}
.help-wrapper .help-container .help-contents-wrapper .help-contents-row .help-content .questions-header .profile-images img:last-child {
  margin-right: 0;
}
.help-wrapper .help-container .help-contents-wrapper .help-contents-row .help-content .questions-content .p-accordion .p-accordion-header a {
  background-color: transparent;
  padding: 24px 0px;
  margin: 0 40px;
  border: none;
  color: #292b2c;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid rgba(41, 43, 44, 0.1);
  position: relative;
  display: flex;
  align-items: center;
}
.help-wrapper .help-container .help-contents-wrapper .help-contents-row .help-content .questions-content .p-accordion .p-accordion-header a .accordion-title {
  display: inline-block;
  margin-left: 10px;
  padding: 0 15px;
}
.help-wrapper .help-container .help-contents-wrapper .help-contents-row .help-content .questions-content .p-accordion .p-accordion-header a .p-accordion-toggle-icon {
  position: absolute;
  right: 0;
  left: auto;
  color: #292b2c;
  font-weight: 600;
}
.help-wrapper .help-container .help-contents-wrapper .help-contents-row .help-content .questions-content .p-accordion .p-accordion-header a.p-state-active {
  background-color: transparent;
}
.help-wrapper .help-container .help-contents-wrapper .help-contents-row .help-content .questions-content .p-accordion .p-accordion-content {
  border: none;
  padding: 22px 28px;
  margin: 0 40px;
  line-height: 1.5;
}

@media (max-width: 992px) {
  .help-wrapper .help-header .header-content {
    width: 90%;
  }
  .help-wrapper .help-container {
    padding: 25px 0;
  }
  .help-wrapper .help-container .help-content-header {
    flex-direction: column;
  }
  .help-wrapper .help-container .help-contents-wrapper .help-contents-row .help-content .questions-content .p-accordion .p-accordion-header {
    padding: 24px 0px;
    margin: 0 10px;
  }
  .help-wrapper .help-container .help-contents-wrapper .help-contents-row .help-content .questions-content .p-accordion .p-accordion-content {
    padding: 22px 28px;
    margin: 0 10px;
  }
}
.invoice-wrapper {
  background-color: #f8f8fc;
}
.invoice-wrapper .invoice-content {
  padding: 25px;
}
.invoice-wrapper .invoice-content .invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}
.invoice-wrapper .invoice-content .invoice-header .invoice-no > h1 {
  font-size: 21px;
  font-weight: 600;
  margin: 0;
  color: #525257;
  line-height: 1.29;
}
.invoice-wrapper .invoice-content .invoice-header .invoice-no > h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  line-height: 1.69;
  color: #7d7d81;
}
.invoice-wrapper .invoice-content .invoice-header .invoice-logo {
  text-align: right;
}
.invoice-wrapper .invoice-content .invoice-header .invoice-logo > img {
  height: 50px;
}
.invoice-wrapper .invoice-content .invoice-header .invoice-logo > p {
  color: #212121;
  line-height: 1.43;
  letter-spacing: 0.25px;
  margin: 0;
}
.invoice-wrapper .invoice-content .invoice-table-1 {
  width: 100%;
  border-radius: 4px;
  border: solid 1px #ebebef;
  background-color: #ffffff;
  margin-bottom: 40px;
}
.invoice-wrapper .invoice-content .invoice-table-1 .invoice-table-header {
  background-color: #ebebef;
  padding: 7px 24px 7px 13px;
}
.invoice-wrapper .invoice-content .invoice-table-1 .invoice-table-header > span {
  color: #525257;
  font-size: 11px;
  font-weight: 600;
}
.invoice-wrapper .invoice-content .invoice-table-1 .invoice-table-content {
  background-color: #ffffff;
  padding: 7px 12px;
}
.invoice-wrapper .invoice-content .invoice-table-1 .invoice-table-content .invoice-table-row > span {
  color: #252529;
  font-size: 13px;
  font-weight: 600;
}
.invoice-wrapper .invoice-content .invoice-table-1.items .invoice-table-content .invoice-table-row {
  padding: 11px 15px;
  border-radius: 4px;
  border: solid 1px #ebebef;
  margin-bottom: 6px;
}
.invoice-wrapper .invoice-content .invoice-footer .invoice-table-2 .invoice-table-col {
  display: flex;
  flex-direction: column;
}
.invoice-wrapper .invoice-content .invoice-footer .invoice-table-2 .invoice-table-col span,
.invoice-wrapper .invoice-content .invoice-footer .invoice-table-2 .invoice-table-col input {
  margin-bottom: 12px;
  padding: 12px 11px;
  border: solid 1px #ebebef;
  color: #252529;
  font-size: 13px;
  font-weight: 600;
}
.invoice-wrapper .invoice-content .invoice-footer .invoice-table-2 .invoice-table-col.header-col {
  text-align: right;
}
.invoice-wrapper .invoice-content .invoice-footer .invoice-table-2 .invoice-table-col.header-col > span {
  background-color: #ebebef;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.invoice-wrapper .invoice-content .invoice-footer .invoice-table-2 .invoice-table-col.content-col > span {
  background-color: #ffffff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.invoice-wrapper .invoice-content .invoice-footer .invoice-table-2.summary .invoice-table-col.header-col {
  margin-left: 40px;
}
.invoice-wrapper .invoice-content .invoice-footer .invoice-table-2.summary .invoice-table-col hr {
  width: 100%;
  opacity: 0.1;
  background-color: #292b2c;
  border: none;
  height: 1px;
  margin: 26px 0;
}

@media (max-width: 768px) {
  .invoice-wrapper .invoice-content .invoice-footer .invoice-table-2.summary {
    margin-top: 40px;
  }
  .invoice-wrapper .invoice-content .invoice-footer .invoice-table-2.summary .invoice-table-col.header-col {
    margin-left: 0;
  }
}
@media print {
  body * {
    visibility: hidden;
  }

  #invoice-content * {
    visibility: visible;
  }

  #invoice-content {
    position: absolute;
    left: 0;
    top: 0;
  }

  #invoice-content .card {
    box-shadow: none;
  }
  #invoice-content .card.invoice-table {
    margin-bottom: 10px;
    background-color: transparent;
  }
}
.wizard-body {
  background: url("../images/extensions/background.jpg") center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.wizard-body .wizard-header {
  padding: 24px 10%;
}
.wizard-body .wizard-header img {
  height: 40px;
}
.wizard-body .wizard-content {
  height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wizard-body .wizard-content .wizard-card {
  border-radius: 4px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.24);
  background-color: #303f46;
  width: 65%;
}
.wizard-body .wizard-content .wizard-card .wizard-card-header .wizard-card-header-banner {
  display: flex;
  height: 110px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-header .wizard-card-header-banner .banner-logo img {
  height: 100%;
}
.wizard-body .wizard-content .wizard-card .wizard-card-header .wizard-card-header-banner .banner-image {
  position: relative;
  display: none;
}
.wizard-body .wizard-content .wizard-card .wizard-card-header .wizard-card-header-banner .banner-image h1 {
  color: #ffffff;
  font-size: 21px;
  font-weight: 500;
  position: absolute;
  left: 24px;
  top: 25px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-header .wizard-card-header-banner .banner-image img {
  width: 100%;
  height: 100%;
}
.wizard-body .wizard-content .wizard-card .wizard-card-header .wizard-card-header-banner .banner-image.active-banner {
  display: block;
}
.wizard-body .wizard-content .wizard-card .wizard-card-header .wizard-card-tabs {
  background-color: #405965;
  display: flex;
  justify-content: space-between;
}
.wizard-body .wizard-content .wizard-card .wizard-card-header .wizard-card-tabs .wizard-card-tab {
  color: #aac8d6;
  font-size: 13px;
  cursor: pointer;
  padding: 15px 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}
.wizard-body .wizard-content .wizard-card .wizard-card-header .wizard-card-tabs .wizard-card-tab:hover {
  background-color: #4e6e7d;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.wizard-body .wizard-content .wizard-card .wizard-card-header .wizard-card-tabs .wizard-card-tab.selected-tab {
  color: #ffffff;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content {
  padding: 25px 30px;
  min-height: 475px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content .wizard-forms-wrapper .wizard-forms .form-label {
  color: #ffffff;
  font-size: 13px;
  margin-bottom: 6px;
  margin-top: 24px;
  display: block;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content .wizard-forms-wrapper .wizard-forms .form-inline-label {
  color: #ffffff;
  font-size: 13px;
  margin-bottom: 2px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content .wizard-button {
  padding: 0 10px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content .wizard-button .p-button {
  background-color: #405965;
  border: none;
  border-radius: 4px;
  padding: 6px 30px;
  font-size: 13px;
  font-weight: 600;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content .wizard-button .p-button:hover {
  background-color: #4b6a79;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.register {
  display: none;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.register .warning {
  display: flex;
  align-items: center;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.register .warning > i {
  color: #e0284f;
  margin-right: 10px;
  font-size: 22px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.register .warning > p {
  color: #ffffff;
  margin: 0;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.register .wizard-forms-wrapper .wizard-forms {
  padding: 0 10px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.register .wizard-forms-wrapper .wizard-forms input {
  width: 100%;
  border: solid 1px #527382;
  background-color: #405965;
  color: #ffffff;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.register .wizard-forms-wrapper .wizard-forms .p-dropdown {
  border: solid 1px #527382;
  width: 100%;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.register .wizard-forms-wrapper .wizard-forms .p-dropdown .p-inputtext {
  background-color: #405965;
  color: #ffffff;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.register .wizard-forms-wrapper .wizard-forms .p-dropdown .p-dropdown-trigger {
  background-color: #405965;
  color: #ffffff;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.register .wizard-forms-wrapper .wizard-forms .calendar .form-label {
  margin-top: 35px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.register .wizard-forms-wrapper .wizard-forms .calendar span {
  width: 100%;
  display: flex;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.register .wizard-forms-wrapper .wizard-forms .calendar span input {
  width: 100%;
  border: solid 1px #527382;
  background-color: #405965;
  color: #ffffff;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.register .wizard-button {
  margin-top: 114px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.tier {
  display: none;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.tier .wizard-tier-cards {
  margin-bottom: 24px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.tier .wizard-tier-cards .wizard-tier-card {
  background-color: #405965;
  border-radius: 4px;
  padding: 0;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
  min-width: 200px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.tier .wizard-tier-cards .wizard-tier-card .wizard-tier-card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom, #5d8191, #4f707f);
  padding: 10px 20px;
  margin-bottom: 10px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.tier .wizard-tier-cards .wizard-tier-card .wizard-tier-card-header img {
  height: 75px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.tier .wizard-tier-cards .wizard-tier-card .wizard-tier-card-content .title > h1 {
  color: #ffffff;
  font-size: 21px;
  font-weight: 500;
  margin: 0;
  padding-left: 5px;
  border-left: 7px solid #d6619b;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.tier .wizard-tier-cards .wizard-tier-card .wizard-tier-card-content .title > span {
  color: #ffffff;
  opacity: 0.7;
  font-size: 14px;
  margin-left: 12px;
  margin-top: 5px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.tier .wizard-tier-cards .wizard-tier-card .wizard-tier-card-content > ul {
  padding: 24px 12px;
  list-style-type: none;
  margin: 0;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.tier .wizard-tier-cards .wizard-tier-card .wizard-tier-card-content > ul > li {
  display: flex;
  align-items: center;
  padding: 7px 0;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.tier .wizard-tier-cards .wizard-tier-card .wizard-tier-card-content > ul > li > i {
  font-size: 20px;
  color: #74909d;
  margin-right: 6px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.tier .wizard-tier-cards .wizard-tier-card .wizard-tier-card-content > ul > li > p {
  margin: 0;
  color: #74909d;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.tier .wizard-tier-cards .wizard-tier-card .wizard-tier-card-content > ul > li.active-list-item i, .wizard-body .wizard-content .wizard-card .wizard-card-content.tier .wizard-tier-cards .wizard-tier-card .wizard-tier-card-content > ul > li.active-list-item p {
  color: #ffffff;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.tier .wizard-tier-cards .wizard-tier-card.beginner .title > h1 {
  border-color: #d6619b;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.tier .wizard-tier-cards .wizard-tier-card.professional .title > h1 {
  border-color: #4dbc90;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.tier .wizard-tier-cards .wizard-tier-card.enterprise .title > h1 {
  border-color: #48bdf1;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.tier .wizard-tier-cards .wizard-tier-card.active-tier-card {
  background-color: #3c6071;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.tier .wizard-tier-cards .wizard-tier-card:hover {
  background-color: #4a6675;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.tier .wizard-button {
  margin-top: 56px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.payment {
  padding: 0;
  display: none;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.payment .wizard-forms-wrapper {
  padding: 24px 40px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.payment .wizard-forms-wrapper .wizard-forms {
  width: 66%;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.payment .wizard-forms-wrapper .wizard-forms .customPanel {
  width: 100%;
  margin-left: 1em;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.payment .wizard-forms-wrapper .wizard-forms .customPanel img {
  height: 35px;
  margin-right: 8px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.payment .wizard-forms-wrapper .wizard-forms .customPanel .p-radiobutton {
  margin-top: 8px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.payment .wizard-forms-wrapper .wizard-forms input {
  width: 100%;
  border: solid 1px #527382;
  background-color: #405965;
  color: #ffffff;
  margin-left: 0.5em;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.payment .wizard-forms-wrapper .wizard-forms .form-label {
  margin-left: 0.5em;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.payment .wizard-forms-wrapper .wizard-forms > .form-label {
  width: 100%;
  display: inline-block;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.payment .wizard-forms-wrapper .wizard-forms .p-checkbox-label {
  color: #ffffff;
  font-size: 13px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.payment .wizard-button {
  position: relative;
  background-image: linear-gradient(to right, rgba(48, 63, 70, 0.15) 23%, rgba(116, 144, 157, 0.15) 60%);
  padding: 24px 48px;
  margin-top: 65px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.payment .wizard-button .order-summary {
  display: none;
  position: absolute;
  left: 65px;
  top: -8px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.payment .wizard-button .order-summary p {
  color: #ffffff;
  font-size: 13px;
  margin: 0;
  margin-bottom: 12px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.payment .wizard-button .order-summary h1 {
  color: #ffffff;
  font-size: 21px;
  margin: 0;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.payment .wizard-button .order-summary span {
  color: #aac8d6;
  font-size: 13px;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.payment .wizard-button .order-summary.selected-order {
  display: block;
}
.wizard-body .wizard-content .wizard-card .wizard-card-content.active-content {
  display: block;
}

@media (min-width: 1200px) {
  .wizard-body .wizard-content .wizard-card {
    width: 55%;
  }
}
@media (max-width: 992px) {
  .wizard-body .wizard-content {
    height: auto;
    align-items: stretch;
  }
  .wizard-body .wizard-content .wizard-card {
    width: 90%;
    overflow: hidden;
  }
  .wizard-body .wizard-content .wizard-card .wizard-card-header .wizard-card-header-banner {
    height: 150px;
  }
  .wizard-body .wizard-content .wizard-card .wizard-card-header .wizard-card-header-banner .banner-image {
    width: 100%;
  }
  .wizard-body .wizard-content .wizard-card .wizard-card-header .wizard-card-header-banner .banner-image img {
    display: none;
  }
  .wizard-body .wizard-content .wizard-card .wizard-card-header .wizard-card-header-banner .banner-image h1 {
    right: 10px;
  }
  .wizard-body .wizard-content .wizard-card .wizard-card-header .wizard-card-tabs .wizard-card-tab {
    padding: 15px 5%;
  }
  .wizard-body .wizard-content .wizard-card .wizard-card-content {
    max-height: 400px;
    overflow: auto;
  }
  .wizard-body .wizard-content .wizard-card .wizard-card-content.register .wizard-button {
    margin-top: 24px;
  }
  .wizard-body .wizard-content .wizard-card .wizard-card-content.tier .wizard-button {
    margin-top: 24px;
  }
  .wizard-body .wizard-content .wizard-card .wizard-card-content.payment .wizard-forms-wrapper .wizard-forms {
    width: 100%;
  }
  .wizard-body .wizard-content .wizard-card .wizard-card-content.payment .wizard-button {
    margin-top: 24px;
    padding: 40px 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .wizard-body .wizard-content .wizard-card .wizard-card-content.payment .wizard-button .order-summary {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .wizard-body .wizard-content .wizard-card .wizard-card-content.payment .wizard-button .p-button {
    height: 50px;
  }
}
/* Add your customizations of layout here */

@media (max-width: 400px) {
  .landing-body .landing-wrapper #pricing .grid .col-12 {
    padding: 20px 0;
  }
}

.p-radiobutton.p-component.p-highlight .p-radiobutton-box {
  background: #fff;
}

.p-radiobutton.p-component.p-highlight .p-radiobutton-box div,
.p-checkbox.p-component.p-highlight .p-checkbox-box {
  background: #46a748 !important;
}
