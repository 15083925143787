/* Mobile styles */
@media (max-width: 767px) {
  .card-container {
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    touch-action: manipulation;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .card-container::-webkit-scrollbar {
    display: none;
  }

  .card-wrapper {
    min-width: 280px;
    flex: 0 0 280px;
    scroll-snap-align: center;
  }
}

/* Desktop styles */
@media (min-width: 768px) {
  .card-container {
    overflow: visible;
  }

  .card-wrapper {
    flex: 1;
    min-width: 0;
  }
}
