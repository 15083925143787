.popup-div_image {
    width: 100vw;
    height: 100vh;
    z-index: 2;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(217, 217, 217, 0.5);
}

.button-div_closeButton {
    position: relative;
    left: 93%;
    top: 5%;
    border: none;
    background-color: white;
    border-radius: 100%;
    aspect-ratio: 1;
    width: 10%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: bold;
    font-size: 1.1rem;
}
