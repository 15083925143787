.gradient-background {
  background: white;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, rgba(126, 229, 192, 1) 0%, rgba(255, 255, 255, 1) 80%);
  background-size: 100% 60%;
  background-repeat: no-repeat;
}

.card-about-shadow {
  box-shadow: 0 0 40px #208C561A;
}
