.registration-card {
  background: #ffffffcc;
  backdrop-filter: blur(8px);
  border-top: 10px solid #46a748;
  height: 100%;
  overflow-x: hidden
}

.specialcustomer-content {
  height: 700px;
  width: 100%;
  background: #ffffff;
  backdrop-filter: blur(8px);
}

.credits-specialcustomer {
  color: white;
  position: fixed;
  left: 0;
  bottom: 0;
  background: rgba(49, 49, 49, 0.8);
  backdrop-filter: blur(5px);
  padding: .2em !important;
}

.credits-specialcustomer a {
  color: white;
}

.credits-specialcustomer a:hover {
  color: black;
  background-color: white;
  transition: 0.1s;
}

.specialcustomer-intro {
  width: 50%;
  margin: 0 auto;
}

.sc-intro-logo {
  height: 7em;
  margin: 1em 0em;
}

.specialcustomer-intro h1 {
  color: white;
  font-weight: 700;
  font-size: 2em;
}

.specialcustomer-intro h4 {
  color: white;
  font-weight: 100;
  font-size: 1.3em;
  margin: 2em 0em;
}

.specialcustomer-intro ul {
  list-style-type: none;
  color: white;
  margin: 2em 0em;
}

.specialcustomer-intro li {
  margin: 1.5em 0em;
}

.specialcustomer-intro a {
  color: #a7c1ff;
}

.registration-card .field {
  margin: 1em;
}

.sc-checkbox {
  margin: .4em;
  padding: 0;
}

.responsive-sc {
  display: none !important;
}

.checkbox-label {
  padding: 0 !important;
  margin: 0 !important;
}

.field {
  margin: .2em .5em !important;
}

.specialcustomer-body {
  display: flex !important;
  height: 100vh;
}

.intro-wrapper {
  position: fixed;
  width: 75%;
}

.logo-intro-desktop {
  display: block;
}

@media screen and (max-width: 991px) {
  .intro-wrapper {
    position: relative;
    height: 100%;
    width: 90%;
    margin: 0 auto;
  }

  .logo-intro-desktop {
    display: none;
  }

  .specialcustomer-body {
    display: none !important;
  }

  .responsive-sc {
    display: block !important;
  }

  .specialcustomer-intro {
    display: block !important;
    position: relative;
    width: 100%;
    margin: 2em auto !important;
  }

  .specialcustomer-intro ul {
    margin: 0;
    padding: 0;
  }

  .specialcustomer-intro h1 {
    color: black;
    text-align: center;
  }

  .specialcustomer-intro h4 {
    color: black;
  }

  .specialcustomer-intro ul {
    color: black;
  }

  .specialcustomer-intro a {
    color: #4038a6;
  }

  .credits-specialcustomer {
    position: absolute;
    top: 0;
    right: 0;
    left: initial;
    bottom: initial;
  }

  .sc-intro-logo {
    margin: 6em 0em !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 100px;
    margin: auto !important;
    height: 20%;
  }

  .responsive-sc-bg {
    width: 100%;
    border-bottom: 5px solid #272360;
  }

  .registration-card {
    border-top: 5px solid #46a748;
    border-bottom: 0px;
    height: 100%;
    padding-bottom: 4em;
    padding-top: 1em;
  }

  .registration-card div button {
    margin-top: 1em;
  }

  .col {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 600px) {
  .sc-intro-logo {
    height: 10%;
  }
}