input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}

button.auto-fill {
  cursor: pointer;
  width: 40%;
  height: fit-content;
  font-family: "Poppins", sans-serif;
  padding: 6px 12px;
  color: white;
  border-radius: 10px;
  border: none;
  background: #272360;
  transition: ease-in-out 0.3s;
}

button.auto-fill:hover {
  background: #1a0f4d;
}

button.shipping-options {
  margin-bottom: 16px;
  cursor: pointer;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-family: "Poppins", sans-serif;
  padding: 6px 12px;
  color: white;
  border-radius: 10px;
  border: none;
  background: #272360;
  transition: ease-in-out 0.3s;
}

button.shipping-options:disabled {
  cursor: not-allowed;
  opacity: 0.85;
}

button.shipping-options:hover {
  background: #1a0f4d;
}

button.return {
  cursor: pointer;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-family: "Poppins", sans-serif;
  padding: 6px 12px;
  color: white;
  border-radius: 10px;
  border: none;
  background: #46A748;
  transition: ease-in-out 0.3s;
}

button.return:hover {
  background: #3A8A3C;
}

.dropdown-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 1.5rem;
  padding: 1rem;
  background: #D4ECD5;
  margin-bottom: 16px;
  transition: ease-in-out 0.3s;
}

.dropdown-header.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.dropdown-header:hover {
  background: #C4E2C5;
}

.dropdown-content {
  max-height: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.dropdown-content.is-open {
  max-height: 9999px;
  opacity: 1;
  overflow: auto;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
}

.row {
  width: 100%;
  display: flex;
  gap: 40px;
  align-items: flex-end;

  .form-field {
    width: 40%;

    label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
      font-family: "Poppins", sans-serif;
    }

    input {
      width: 100%;
      padding: 8px;
      border-radius: 5px;
      border: 1px solid #e0e0e0;
      font-family: "Poppins", sans-serif;
    }

    input:disabled {
      /* background: #f0f0f0; */
      cursor: not-allowed;
      background: #fff;
      opacity: 0.7;
    }
  }

}

@media (max-width: 768px) {
  .row {
    gap: 16px;

    .form-field {
      width: 100%;
  }
}

  .cep {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .form-field {
      width: 100%;
    }
  }

  button.auto-fill {
    width: 100%;
  }
}

.input-amount {
  min-width: 65px;
}

.proceed-to-payment {
  padding: 16px !important;

  @media (max-width: 768px) {
    min-height: 44px !important;
    width: 100% !important;
    font-size: 1rem !important;
    padding: 2px !important;
  }
}
