#footer {
    padding: 42px 100px;
}

@media screen and (max-width: 1200px) {
    #footer {
        padding-left: 20px;
        padding-right: 20px;
    }
}
