.card-blur-wr {
  position: absolute;
  backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, 0.1);
}

.card-blur-wr-1 {
  left: 30px;
  top: 30px;
}

.card-blur-wr-2 {
  right: 60px;
  top: 60px;
}

.card-blur-wr-3 {
  left: 40px;
  bottom: 90px;
  max-width: 233px;
}

.card-blur-wr-4 {
  right: 30px;
  bottom: 70px;
}

@media (max-width: 1200px) {
  .card-blur-wr-1 {
    left: 0;
    max-width: 156px;
  }

  .card-blur-wr-2 {
    right: 0;
    max-width: 113px;
  }

  .card-blur-wr-3 {
    left: 0;
    max-width: 154px;
  }

  .card-blur-wr-4 {
    right: 0;
    max-width: 182px;
  }
}
