.msg-card-responsive {
  display: block;
}

.msg-card {
  display: none;
}

.exception-body .exception-content img {
  margin-bottom: 5em !important;
}

@media screen and (min-width: 768px) {
  .msg-card-responsive {
    display: none;
  }

  .msg-card {
    display: block;
  }

  .exception-body .exception-content img {
    margin-bottom: 7em !important;
  }
}