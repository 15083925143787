.leaflet-container {
  height: 400px;
  width: 100%;
  position: relative;
}

.chip {
  display: inline-block;
  padding: 0 25px;
  height: 50px;
  font-size: 16px;
  line-height: 50px;
  border-radius: 25px;
  background-color: #f1f1f1;
}

.chip img {
  float: left;
  margin: 0 10px 0 -25px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.static-map {
  width: 250px;
  height: 250px;
}

.map-search-stores {
  position: absolute;
  z-index: 998;
  right: 0;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  gap: 8px;
}
