.product-title {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.productCard-title {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-item,
.product-item-content {
  width: 100%;
}

.productCardImg {
  max-width: 250px;
  /* Largura máxima */
  width: 100%;
  height: 100%;
  /* Altura 100% para ocupar o espaço disponível */
  object-fit: cover;
  /* Manter a proporção da imagem e cobrir todo o espaço */
  margin: 0 auto;
  /* Centralizar horizontalmente */
  display: block;
  /* Garantir que a margem automática funcione */

}

.product-thumb:hover {
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: .2s;
}

.more-details:hover {
  text-decoration: underline;
  background-color: #027abc !important;
  transition: .2s;
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #91c8e6;
}

.justify-self-start {
  justify-self: start;
}

.min-width {
  min-width: 265px;
}

.secondary-banners {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
}

.banner-gradient-background {
  background-image: linear-gradient(to right, #e9f4e3, #c0f6e2);
}

.category-template {
  min-width: 150px;
  max-width: 200px;
}

.img-primaryBanner {
  width: 400px;
}

.shop-products-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  gap: 5px;
}

@media (max-width: 1200px) {
  .img-primaryBanner {
    width: 200px;
  }
}

@media (max-width: 568px) {
  .shop-products-section {
    grid-template-columns: 1fr 1fr;
  }

  .category-template {
    min-width: 120px;
  }

  .productCardMinimalPrice {
    width: 13ch;
  }
}
