.div-container_privacy {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 20px;
}

.section-container_privacy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px 0;
}

.h1-title_privacy {
    font-weight: bold;
    color: #000;
    font-size: 2rem;
}

.p-text_privacy {
    color: #000;
}
