.ruled-grid {
  display: grid;
  grid-template-columns: 1fr;
  align-items: stretch;
  overflow: hidden;
  gap: 1rem;
}

@media (min-width: 1024px) {
  .ruled-grid {
    grid-template-columns: repeat(2, 1fr); /* Exactly 2 columns, no more */
  }
}

.ruled-grid > .grid-card {
  position: relative;
  padding: 1.5em;
}

/* Linhas */
.ruled-grid > .grid-card::after {
  content: "";
  background-color: #7ee5c0;
  position: absolute;
  width: 100vw;
  height: 0.5px;
  top: -0.2em;
  left: 0;
}

/* Colunas */
.ruled-grid > .grid-card::before {
  content: "";
  background-color: #7ee5c0;
  position: absolute;
  height: 100vh;
  width: 0.5px;
  top: 0;
  left: -0.2em;
}
