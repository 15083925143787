.marginLeft {
  padding: 0;
  margin-left: 0px;
  background-color: transparent !important;
}

@media (max-width: 1200px) {
  .marginLeft {
    margin-left: 22px;
    padding: 12px 24px !important;
  }
}